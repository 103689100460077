import {Route, Routes} from "react-router-dom";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Teams from "./components/Teams/Teams";
import CreateLeague from "./pages/CreateLeague";
import CreateTeam from "./pages/CreateTeam";
import CreatePlayer from "./pages/CreatePlayer";
import Users from "./pages/Users";
import AllLeague from "./pages/AllLeague";
import LoadingMatch from "./pages/LoadingMatch";
import CreateNewReport from "./pages/CreateNewReport";
import NewReportInfo from "./pages/NewReportInfo";
import NewReportInfoPdf from "./pages/NewReportInfoPdf"

function App() {

    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/league" element={<AllLeague/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/users" element={<Users/>}/>
            <Route path="/new-report-info" element={<NewReportInfo/>}/>
            <Route path="/create-new-report" element={<CreateNewReport/>}/>
            <Route path="/loading-match" element={<LoadingMatch/>}/>
            <Route exact path="/teams/:id" element={<Teams/>}/>
            <Route exact path="/create-league" element={<CreateLeague/>}/>
            <Route exact path="/create-team" element={<CreateTeam/>}/>
            <Route exact path="/create-player" element={<CreatePlayer/>}/>
            <Route path="/new-report-info-pdf" element={<NewReportInfoPdf/>}/>
        </Routes>
    );
}

export default App;
