import Header from "../components/Header/Header";
import League from "../components/League/League";

const AllLeague = () => {
    return (
        <>
            <Header />
            <League />
        </>
    );
};

export default AllLeague;