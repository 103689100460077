import React, {useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";

import {ReactComponent as Logo} from "../../assets/images/logo.svg";
import styles from './Header.module.css';
import {useDispatch, useSelector} from "react-redux";
import {clearCurrentUser, currentUser} from "../../store/slices/userSlice";
import axios from "axios";


const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {role} = useSelector(state => state.user.userInfo);

    useEffect(() => {
        if(document.cookie) {
            axios.get(`${process.env.REACT_APP_SERVER_URL}api/user/info`, {
                headers: {"Authorization": document.cookie}
            })
                .then(res => {
                    if (res.status === 200) {
                        dispatch(currentUser(res.data.data.user));
                    }
                }).catch(error => console.error(error))
        }
    }, [dispatch])

    const logout = () => {
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        dispatch(clearCurrentUser());
        navigate('/login');
    }

    return (
        <header className={styles.header}>
            <div className="container">
                <Link className={styles.logo} to="/"><Logo/></Link>

                <ul className={styles.menuList}>
                    <li className={styles.menuItem}>
                        <Link to="/">Аналітика</Link>
                    </li>
                    {(role === 'admin' || role === 'moderator') ?
                        <>
                            <li className={styles.menuItem}>
                                <Link to="/loading-match">Вигрузка матчу</Link>
                            </li>
                            {role === 'admin' ?
                                <li className={styles.menuItem}>
                                    <Link to="/users">Користувачі</Link>
                                </li>
                                : null
                            }
                            <li className={styles.menuItem}>
                                <Link to="/league">База даних</Link>

                                <ul className={styles.subMenu}>
                                    <li className={styles.menuItem}>
                                        <Link to="/create-league">Додати лігу</Link>
                                    </li>
                                    <li className={styles.menuItem}>
                                        <Link to="/create-team">Додати команду</Link>
                                    </li>
                                    <li className={styles.menuItem}>
                                        <Link to="/create-player">Додати гравця</Link>
                                    </li>
                                </ul>
                            </li>
                        </>
                        : null
                    }

                    {role !== '' ?
                        <li className={styles.menuItem}>
                            <button onClick={logout}>Вихід</button>
                        </li>
                        : null
                    }

                </ul>
            </div>
        </header>
    );
};

export default Header;