import Header from "../components/Header/Header";
import {useSelector} from "react-redux";
import {Navigate, Link} from "react-router-dom";
import StatisticAction from "../components/StatisticListInfo/StatisticAction";
import StatisticBallMovement from "../components/StatisticListInfo/StatisticBallMovement";
import StatisticLines from "../components/StatisticListInfo/StatisticLines";
import {formatName} from "../helpers/formatName";
import StatisticGoalkeeper from "../components/StatisticListInfo/StatisticGoalkeeper";

import {ReactComponent as Ball} from "../assets/images/ball.svg";
import {ReactComponent as RedCard} from "../assets/images/red-card.svg";
import {ReactComponent as YellowCard} from "../assets/images/yellow-card.svg";

const NewReportInfo = () => {
    const report = useSelector(state => state.newReport.currentNewReport);
console.log(report)



    if(Object.keys(report).length === 0) return <Navigate to="/" />

    const IconRating = ( rating ) => {
        const icons = [];
        for (let i = 0; i < rating; i++) {
            icons.push(<Ball key={i} />);
        }
        return <div>{icons}</div>;
    };


    return (
        <>
            <Header/>

            <div className="new-report container">
                <div className="new-report__header">


                    <div className="new-report__header-left">
                        <h2>{report.client}</h2>
                        <Link style={{ color: "white" }} to="/new-report-info-pdf">PDF</Link>
                        <div style={{paddingLeft: '20px', borderLeft: '2px solid #fff'}}>
                            <h3>Звіт по матчу</h3>
                            <span>{report.report.teamA.name} - {report.report.teamB.name}</span>
                            <span style={{marginTop: '5px'}}>{report.title}</span>
                            <span style={{marginTop: '5px'}}>{report.tour}</span>

                        </div>
                    </div>
                    <div className="new-report__header-right">
                        <div>
                            <div style={{display: 'flex', gap: '10px'}}>
                                <img src={`${process.env.REACT_APP_SERVER_URL}${report.report.teamA.logotype}`} alt="logo1"/>
                                <img src={`${process.env.REACT_APP_SERVER_URL}${report.report.teamB.logotype}`} alt="logo2"/>
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <p style={{marginBottom: '10px', fontSize: '18px'}}>{report.leagueName}</p>
                                <p>{new Date(report.dateMatch).toLocaleDateString()}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="new-report__body">
                    <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                        <div className="make-teams">
                            <h2>Склади команд</h2>
                            <div className="make-teams__wrapper">
                                <ul className="make-teams__list">
                                    {report.report.teamA?.composition.map(({number, player, position, replacementTime, goalsPlayer, status}, index) =>
                                        <li key={index} className="make-teams__item">
                                            <span style={{width: '40px'}}>{position}</span>
                                            <span style={{width: '28px', textAlign: 'center', background: 'lightseagreen', padding: '2px 4px'}}>{number}</span>
                                            <span title={player} style={{width: '180px', whiteSpace: 'nowrap'}}>{player.length > 20 ? formatName(player) : player}</span>
                                            <span style={{width: '20px', color: 'red'}}>{replacementTime}</span>

                                            {<div className="make-teams__icons">{IconRating(goalsPlayer)}</div>}

                                            <div className="make-teams__icons">{status.map((s, index) =>
                                                <div key={index}>
                                                    {s === 'goal' ? <Ball/> : null}
                                                    {s === 'yellowCard' ? <YellowCard/> : null}
                                                    {s === 'redCard' ? <RedCard/> : null}
                                                </div>
                                            )}</div>
                                        </li>
                                    )}
                                </ul>
                                <ul className="make-teams__list">
                                    {report.report.teamB.composition.map(({number, player, position, replacementTime, status, goalsPlayer}, index) =>
                                        <li key={index} className="make-teams__item">
                                            <span style={{width: '36px'}}>{position}</span>
                                            <span style={{width: '28px', textAlign: 'center', background: '#b9354a', padding: '2px 4px'}}>{number}</span>
                                            <span title={player} style={{width: '180px', whiteSpace: 'nowrap'}}>{player.length > 20 ? formatName(player) : player}</span>
                                            <span style={{width: '20px', color: 'red'}}>{replacementTime}</span>

                                            {<div className="make-teams__icons">{IconRating(goalsPlayer)}</div>}

                                            <div className="make-teams__icons">{status.map((s, index) =>
                                                <div key={index}>
                                                    {s === 'goal' ? <Ball/> : null}
                                                    {s === 'yellowCard' ? <YellowCard/> : null}
                                                    {s === 'redCard' ? <RedCard/> : null}
                                                </div>
                                            )}</div>
                                        </li>
                                    )}
                                </ul>
                            </div>

                            <h3>Заміни</h3>
                            <div className="make-teams__wrapper">
                                <ul className="make-teams__list">
                                    {report.report.teamA?.replacement.map(({number, player, position, replacementTime, goalsPlayer, status}, index) =>
                                        <li key={index} className="make-teams__item">
                                            <span style={{width: '40px'}}>{position}</span>
                                            <span style={{width: '28px', textAlign: 'center', background: 'lightseagreen', padding: '2px 4px'}}>{number}</span>
                                            <span title={player} style={{width: '180px', whiteSpace: 'nowrap'}}>{player.length > 20 ? formatName(player) : player}</span>
                                            <span style={{width: '20px', color: 'green'}}>{replacementTime}</span>
                                            {<div className="make-teams__icons">{IconRating(goalsPlayer)}</div>}
                                            {status &&   <div className="make-teams__icons">{status.map((s, index) =>
                                                <div key={index}>
                                                    {s === 'goal' ? <Ball/> : null}
                                                    {s === 'yellowCard' ? <YellowCard/> : null}
                                                    {s === 'redCard' ? <RedCard/> : null}
                                                </div>
                                            )}</div> }


                                        </li>
                                    )}
                                </ul>
                                <ul className="make-teams__list">
                                    {report.report.teamB.replacement.map(({number, player, position, replacementTime, goalsPlayer, status}, index) =>
                                        <li key={index} className="make-teams__item">
                                            <span style={{width: '36px'}}>{position}</span>
                                            <span style={{width: '28px', textAlign: 'center', background: '#b9354a', padding: '2px 4px'}}>{number}</span>
                                            <span title={player} style={{width: '180px', whiteSpace: 'nowrap'}}>{player.length > 20 ? formatName(player) : player}</span>
                                            <span style={{width: '20px', color: 'green'}}>{replacementTime}</span>
                                            {<div className="make-teams__icons">{IconRating(goalsPlayer)}</div>}
                                            {status &&
                                                <div className="make-teams__icons">{status.map((s, index) =>
                                                    <div key={index}>
                                                        {s === 'goal' ? <Ball/> : null}
                                                        {s === 'yellowCard' ? <YellowCard/> : null}
                                                        {s === 'redCard' ? <RedCard/> : null}
                                                    </div>
                                                )}</div> }
                                        </li>
                                    )}
                                </ul>
                            </div>

                            <h3>Запасні</h3>
                            <div className="make-teams__wrapper">
                                <ul className="make-teams__list">
                                    {report.report.teamA?.spare.map(({number, player, status, position}, index) =>
                                        <li key={index} className="make-teams__item">
                                            <span style={{width: '36px'}}>{position}</span>
                                            <span style={{width: '28px', textAlign: 'center', background: 'lightseagreen', padding: '2px 4px'}}>{number}</span>
                                            <span title={player} style={{width: '180px', whiteSpace: 'nowrap'}}>{player.length > 20 ? formatName(player) : player}</span>
                                            {status &&   <div className="make-teams__icons">{status?.map((s, index) =>
                                                <div key={index}>
                                                    {/*{s === 'goal' ? <Ball/> : null}*/}
                                                    {s === 'yellowCard' ? <YellowCard/> : null}
                                                    {s === 'redCard' ? <RedCard/> : null}
                                                </div>
                                            )}</div> }

                                        </li>
                                    )}
                                </ul>
                                <ul className="make-teams__list">
                                    {report.report.teamB.spare.map(({number, player,status, position}, index) =>
                                        <li key={index} className="make-teams__item">
                                            <span style={{width: '36px'}}>{position}</span>
                                            <span style={{width: '28px', textAlign: 'center', background: '#b9354a', padding: '2px 4px'}}>{number}</span>
                                            <span title={player} style={{width: '180px', whiteSpace: 'nowrap'}}>{player.length > 20 ? formatName(player) : player}</span>
                                            {status &&
                                                <div className="make-teams__icons">{status?.map((s, index) =>
                                                    <div key={index}>
                                                        {/*{s === 'goal' ? <Ball/> : null}*/}
                                                        {s === 'yellowCard' ? <YellowCard/> : null}
                                                        {s === 'redCard' ? <RedCard/> : null}
                                                    </div>
                                                )}</div> }
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="new-report__screens">
                            <div>
                               <p>{report.report.teamA.name}</p>
                                <img src={`${process.env.REACT_APP_SERVER_URL}${report.report.teamA.locationPlayers}`} alt="logo1"/>
                            </div>
                            <div>
                                <p>{report.report.teamB.name}</p>
                                <img src={`${process.env.REACT_APP_SERVER_URL}${report.report.teamB.locationPlayers}`} alt="logo2"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-break"></div>

                <div className="new-report__body">
                    <div className="new-report__statistic statistic">
                        <h2>Весь матч</h2>

                       <StatisticAction  data={report.report.statistics.allHalf.actions} sum={report.report.teamCoefficients.allHalf} title="Загальні дії" />



                        <StatisticAction data={report.report.statistics.halfOne.actions} sum={report.report.teamCoefficients.halfOne} title="Загальні дії 1 тайм" />

                        <StatisticAction data={report.report.statistics.halfTwo.actions} sum={report.report.teamCoefficients.halfTwo} title="Загальні дії 2 тайм" />

                        <div className="page-break"></div>

                        <StatisticBallMovement data={report.report.statistics.allHalf.ballMovement} sum={report.report.teamCoefficients.allHalf} title="Рух мʼяча" />

                        <StatisticBallMovement data={report.report.statistics.halfOne.ballMovement} sum={report.report.teamCoefficients.halfOne} title="Рух мʼяча 1 тайм" />

                        <StatisticBallMovement data={report.report.statistics.halfTwo.ballMovement} sum={report.report.teamCoefficients.halfTwo} title="Рух мʼяча 2 тайм" />
                    </div>

                    <div className="page-break"></div>

                    <div className="new-report__statistic statistic">
                        <StatisticGoalkeeper data={report.report.goalkeepers} />
                    </div>

                    <div className="new-report__statistic statistic">
                        <StatisticLines data={report.report.defense} title="Лінія захисту"/>

                        <StatisticLines data={report.report.midfield} title="Лінія півзахисту"/>

                        <div className="page-break"></div>

                        <StatisticLines data={report.report.attackers} title="Нападники"/>

                        <StatisticLines data={report.report.replacementLine} title="Заміни"/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewReportInfo;