import Header from "../components/Header/Header";
import AdminDashboard from "../components/AdminDashboard/AdminDashboard";

const Users = () => {
    return (
        <>
           <Header/>
            <AdminDashboard/>
        </>
    );
};

export default Users;