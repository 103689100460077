import {Button, Form, Input, Modal, DatePicker, Upload, message, Select} from "antd";
import 'dayjs/locale/uk';
import locale from 'antd/es/date-picker/locale/uk_UA';
import {UploadOutlined} from "@ant-design/icons";
import axios from "axios";
import {toggleLoading} from "../../store/slices/analyticsSlice";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {dateFormat} from "../../helpers/dateFormat";

const ModalCreateOldReport = ({modalOldReport, setModalOldReport}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const [typeRep, setTypeRep] = useState('kolos');

    const displaySuccessMessage = () => {
        messageApi.open({
            style: { textAlign: 'center' },
            type: 'success',
            content: 'Доклад завантажено!',
        });
    };

    const createOldReport = async (values) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('date', dateFormat(values.date));
        formData.append('type', values.type);
        formData.append('report', values.report);

        try {
            const { status } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/reports/old/load`, formData, {
                headers: { Authorization: document.cookie },
            });

            if (status === 200) {
                form.resetFields();
                displaySuccessMessage();
                dispatch(toggleLoading());
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onClose = () => {
        setModalOldReport(false);
        form.resetFields();
    };

    return (
        <>
            {contextHolder}
            <Modal
                title="Завантажити старий доклад"
                width="350px"
                open={modalOldReport === true}
                onCancel={onClose}
                footer={null}
                destroyOnClose
            >
                <Form
                    form={form}
                    name="createReport"
                    autoComplete="off"
                    onFinish={createOldReport}
                >
                    <Form.Item
                        name="name"
                        style={{margin: '20px 0 0'}}
                        rules={[
                            {
                                required: true,
                                message: 'Введіть назву!'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Назва"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="date"
                        rules={[
                            {
                                required: true,
                                message: 'Виберіть дату!'
                            }
                        ]}
                    >
                        <DatePicker
                            placeholder="Виберіть дату"
                            locale={locale}
                            className="color-black"
                            style={{width: '100%', marginTop: '15px'}}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="type"
                        initialValue={typeRep}
                    >
                        <Select
                            style={{ width: '100%' }}
                            size="large"
                            onChange={(value) => setTypeRep(value)}
                            options={[
                                { value: 'kolos', label: 'kolos' },
                                { value: 'scouting', label: 'scouting' },
                                { value: 'kolos_u19', label: 'kolos_u19' },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        name="report"
                        getValueFromEvent={(e) => e.file}
                        valuePropName="file"
                        rules={[
                            {
                                required: true,
                                message: 'Загрузіть pdf файл!'
                            }
                        ]}
                    >
                        <Upload
                            maxCount={1}
                            accept=".pdf"
                            className="file-label"
                            beforeUpload={(file) => {
                                const reader = new FileReader();
                                reader.readAsText(file);
                                return false;
                            }}
                        >
                            <Button style={{width: '100%'}} size="large" icon={<UploadOutlined/>}>
                                 Загрузити pdf
                            </Button>
                        </Upload>
                    </Form.Item>

                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        style={{width: '100%'}}
                    >
                        Створити
                    </Button>
                </Form>
            </Modal>
        </>
    );
};

export default ModalCreateOldReport;