import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeUserValue, getAllUsers, renderList} from "../../store/slices/userSlice";
import axios from "axios";

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

import ModalCreateUser from "../Modal/ModalCreateUser";
import ModalUpdateUser from "../Modal/ModalUpdateUser";


import styles from './AdminDashboard.module.css';


const { confirm } = Modal;

const AdminDashboard = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.user.userInfo);
    const users = useSelector(state => state.user.allUsers);
    const renderUsers = useSelector(state => state.user.renderList);

    const [isLoading, setIsLoading] = useState(true);
    const [modalCreateUser, setModalCreateUser] = useState(false);
    const [modalUpdateUser, setModalUpdateUser] = useState(false);


    const deleteUser = (id, name) => {
        confirm({
            title: `Видалити користувача ${name}?`,
            icon: <ExclamationCircleFilled />,
            cancelText: 'Відмінити',
            okText: 'Видалити',
            onOk() {
                axios({
                    method: 'DELETE',
                    url: `${process.env.REACT_APP_SERVER_URL}api/user/delete`,
                    headers: {"Authorization": document.cookie},
                    data: {id: id}
                })
                dispatch(renderList());
            },
        });
    };

    const updateUser = (user) => {
        dispatch(changeUserValue(user))
        setModalUpdateUser(true);
    }

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_SERVER_URL}api/user/list`, {
            headers: {"Authorization": document.cookie},
        }).then(res => {
            dispatch(getAllUsers(res.data.data.users));
            setIsLoading(false);
        }).catch(error => console.error(error))
    }, [dispatch, renderUsers])

    return (
        <div className="container" style={{paddingTop: '80px'}}>
            <button
                className={styles.createUser}
                onClick={() => setModalCreateUser(true)}
            >
                Створити користувача
                <svg className={styles.createUserIcon} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256C397.4 512 512 397.4 512 256S397.4 0 256 0zM352 280H280V352c0 13.2-10.8 24-23.1 24C242.8 376 232 365.2 232 352V280H160C146.8 280 136 269.2 136 256c0-13.2 10.8-24 24-24H232V160c0-13.2 10.8-24 24-24C269.2 136 280 146.8 280 160v72h72C365.2 232 376 242.8 376 256C376 269.2 365.2 280 352 280z"/>
                </svg>
            </button>

            {isLoading ?
                <div className="preloader">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                : <ul className={styles.usersList}>
                    {users.filter(user => user._id !== currentUser._id).map(({date, email, name, role, _id, password}) =>
                        <li key={_id} className={styles.usersItem}>
                            <span>{name}</span>
                            <span>{email}</span>
                            <span>{new Date(date).toLocaleString()}</span>
                            <span>{role}</span>
                            <div className={styles.buttonsWrapper}>
                                <button className={styles.usersItemIcon} onClick={() => updateUser({email, name, role, _id, password})}>
                                    <svg viewBox="0 0 576 512">
                                        <path fill="green" d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"/>
                                    </svg>
                                </button>
                                <button className={styles.usersItemIcon} onClick={() => deleteUser(_id, name)}>
                                    <svg height="24px" version="1.2" viewBox="0 0 24 24" width="24px">
                                        <path fill="#e22d2d" d="M12,4c-4.419,0-8,3.582-8,8s3.581,8,8,8s8-3.582,8-8S16.419,4,12,4z M15.707,14.293c0.391,0.391,0.391,1.023,0,1.414  C15.512,15.902,15.256,16,15,16s-0.512-0.098-0.707-0.293L12,13.414l-2.293,2.293C9.512,15.902,9.256,16,9,16  s-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414L10.586,12L8.293,9.707c-0.391-0.391-0.391-1.023,0-1.414  s1.023-0.391,1.414,0L12,10.586l2.293-2.293c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414L13.414,12L15.707,14.293z"/>
                                    </svg>
                                </button>
                            </div>
                        </li>
                    )}

                </ul>
            }

            <ModalCreateUser
                modalCreateUser={modalCreateUser}
                setModalCreateUser={setModalCreateUser}
            />

            <ModalUpdateUser
                modalUpdateUser={modalUpdateUser}
                setModalUpdateUser={setModalUpdateUser}
            />
        </div>
    );
};

export default AdminDashboard;