import React, {useEffect, useState} from 'react';

import Header from "../components/Header/Header";
import {Form, message, Select} from "antd";

import FootballField from "../components/FootballField/FootballField";
import axios from "axios";
import NewReportTeam from "../components/NewMatchReport/NewReportTeam";
import NewReportHeader from "../components/NewMatchReport/NewReportHeader";
import NewReportActions from "../components/NewMatchReport/NewReportActions";
import {dateFormat, timeFormat} from "../helpers/dateFormat";
import {objectToValuesArray} from "../helpers/objectToArray";


const CreateNewReport = () => {
    const [form] = Form.useForm();


    const initialState = (count) => Array.from({length: count}, (_, index) => index + 1);

    const [messageApi, contextHolder] = message.useMessage();
    const [images, setImages] = useState({
        firstImg: null,
        secondImg: null,
    });
    const [teamPlayers, setTeamPlayers] = useState({
        teamFirst: {
            composition: initialState(1),
            replacement: initialState(1),
            spare: initialState(1),
        },
        teamSecond: {
            composition: initialState(1),
            replacement: initialState(1),
            spare: initialState(1),
        },
    });
    const [screenFirstField, setScreenFirstField] = useState(null);
    const [screenSecondField, setScreenSecondField] = useState(null);
    const [allTeams, setAllTeams] = useState([]);
    const [allAnalytics, setAllAnalytics] = useState([]);
    const [playersFirstTeam, setPlayersFirstTeam] = useState([]);
    const [playersSecondTeam, setPlayersSecondTeam] = useState([]);
    const [typeRep, setTypeRep] = useState('kolos');
    const [nameTeamFirst, setNameTeamFirst] = useState("")
    const [nameTeamSecond, setNameTeamSecond] = useState('')



    const getNameOfTeamFirst = (idOfTeam) =>{
        const firstTeam = allTeams.find(team => idOfTeam === team?._id)
        return setNameTeamFirst( firstTeam.name)

    }
    const getNameOfTeamSecond = (idOfTeam) =>{
        const firstTeam = allTeams.find(team => idOfTeam === team?._id)
        return setNameTeamSecond( firstTeam.name)

    }

    useEffect(() => {
        axios(`${process.env.REACT_APP_SERVER_URL}api/team/get-all`, {headers: {"Authorization": document.cookie}})
            .then(res => {
                if (res.status === 200) {
                    setAllTeams(res.data.data.teams);
                }
            })
            .catch(err => console.error(err));

        axios(`${process.env.REACT_APP_SERVER_URL}api/analytics/get`, {headers: {"Authorization": document.cookie}})
            .then(res => {
                if (res.status === 200) {
                    setAllAnalytics(res.data.data.analytics);
                }
            })
            .catch(err => console.error(err));
    }, [])

    const
        optionTeams = allTeams.map(({name, _id}) => {
            return {value: _id, label: name};
        }),
        optionAnalytic = allAnalytics.map(({name, _id}) => {
            return {value: _id, label: name};
        });





    const optionsPlayers = (id, teamSetter) => {
        axios(`${process.env.REACT_APP_SERVER_URL}api/team/get-players?id=${id}`, {headers: {"Authorization": document.cookie}})
            .then(res => {
                const players = res.data.data.players;
                teamSetter(players);
            })
            .catch(err => console.error(err));
    };

    const displayMessage = (type, content) => {
        messageApi.open({
            style: {textAlign: 'center'},
            type,
            content,
        });
    };

    const createNewReport = async (values) => {
        const formData = new FormData();


        formData.append('teamNameFirst', values.teamNameFirst);
        formData.append('teamNameSecond', values.teamNameSecond);

        formData.append('logoFirstTeam', images.firstImg ? images.firstImg : null);
        formData.append('logoSecondTeam', images.secondImg ? images.secondImg : null);

        formData.append('firstScreen', screenFirstField);
        formData.append('secondScreen', screenSecondField);

        formData.append('client', values.client);
        formData.append('title', values.title);

        formData.append('dateMatch', dateFormat(values.dateMatch));
        formData.append('holdingTime', timeFormat(values.holdingTime));

        formData.append('leagueName', values.leagueName);
        formData.append('tour', values.nameTour);

        formData.append('analytic', values.analytic);
        formData.append('analyticType', values.analyticType);

        formData.append('teamFirst', JSON.stringify(values.teamFirst));
        formData.append('teamSecond', JSON.stringify(values.teamSecond));

        formData.append('teamFirstId', values.selectTeamFirst);
        formData.append('teamSecondId', values.selectTeamSecond);

        formData.append('action', JSON.stringify(objectToValuesArray(values.action)));
        formData.append('attackers', JSON.stringify(objectToValuesArray(values.attackers)));
        formData.append('defense', JSON.stringify(objectToValuesArray(values.defense)));
        formData.append('goalkeepers', JSON.stringify(objectToValuesArray(values.goalkeepers)));
        formData.append('midfield', JSON.stringify(objectToValuesArray(values.midfield)));
        formData.append('replacementLine', JSON.stringify(objectToValuesArray(values.replacementLine)));
        formData.append('ballMovement', JSON.stringify(objectToValuesArray(values.ballMovement)));

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_URL}api/reports/create`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": document.cookie
            },
        })
            .then(res => {
                if (res.status === 200) {
                    displayMessage('success', 'Звіт створено!')
                }
            })
            .catch(error => {
                displayMessage('error', error.response.data.message)
            });

    };


    return (
        <>
            {contextHolder}
            <Header/>
            <Form
                form={form}
                name="newReport"
                autoComplete="off"
                onFinish={createNewReport}
                onFinishFailed={() => displayMessage('error', 'Заповніть всі поля !!!')}
                className="new-report"
            >
                <div className="container">

                    <NewReportHeader setImages={setImages} images={images}/>

                    <div className="nr-container">
                        <div className="nr-wrapper">
                            <div className="nr-body">
                                <div style={{width: '100%', display: 'flex', alignItems: 'center', gap: "15px"}}>
                                    <h2 className="nr-body__title" style={{margin: '0 20px 0 0', width: 'auto'}}>Склади
                                        команд</h2>
                                    <Form.Item
                                        style={{margin: '0'}}
                                        name="selectTeamFirst"
                                        rules={[
                                            {
                                                required: true,
                                                message: ''
                                            }
                                        ]}
                                    >
                                        <Select
                                            className="select-custom"
                                            showSearch
                                            style={{width: '195px', margin: '0'}}
                                            placeholder="Перша команда"
                                            options={optionTeams}
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            onChange={(value) =>  optionsPlayers(value, setPlayersFirstTeam, getNameOfTeamFirst(value))  }



                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{margin: '0'}}
                                        name="selectTeamSecond"
                                        rules={[
                                            {
                                                required: true,
                                                message: ''
                                            }
                                        ]}
                                    >
                                        <Select
                                            className="select-custom"
                                            showSearch
                                            style={{width: '195px', margin: '0'}}
                                            placeholder="Друга команда"
                                            options={optionTeams}
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            onChange={(value) =>  optionsPlayers(value, setPlayersSecondTeam, getNameOfTeamSecond(value))}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{margin: '0'}}
                                        name="analytic"
                                        rules={[
                                            {
                                                required: true,
                                                message: ''
                                            }
                                        ]}
                                    >
                                        <Select
                                            className="select-custom"
                                            showSearch
                                            style={{width: '195px', margin: '0'}}
                                            placeholder="Аналітика"
                                            options={optionAnalytic}
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{margin: '0'}}
                                        name="analyticType"
                                        initialValue={typeRep}
                                    >
                                        <Select
                                            style={{width: '115px', margin: '0'}}
                                            className="select-custom"
                                            onChange={(value) => setTypeRep(value)}
                                            options={[
                                                { value: 'kolos', label: 'kolos' },
                                                { value: 'scouting', label: 'scouting' },
                                                { value: 'kolos_u19', label: 'kolos_u19' },
                                            ]}
                                        />
                                    </Form.Item>
                                </div>
                                <NewReportTeam
                                    team="teamFirst"
                                    category="composition"
                                    teamPlayers={teamPlayers.teamFirst.composition}
                                    setTeamPlayers={setTeamPlayers}
                                    playersFirstTeam={playersFirstTeam}
                                    playersSecondTeam={playersSecondTeam}
                                />
                                <NewReportTeam
                                    team="teamSecond"
                                    category="composition"
                                    teamPlayers={teamPlayers.teamSecond.composition}
                                    setTeamPlayers={setTeamPlayers}
                                    playersFirstTeam={playersFirstTeam}
                                    playersSecondTeam={playersSecondTeam}
                                />
                            </div>

                            <div className="nr-body">
                                <h2 className="nr-body__title">Заміни</h2>
                                <NewReportTeam
                                    team="teamFirst"
                                    category="replacement"
                                    teamPlayers={teamPlayers.teamFirst.replacement}
                                    setTeamPlayers={setTeamPlayers}
                                    playersFirstTeam={playersFirstTeam}
                                    playersSecondTeam={playersSecondTeam}
                                />
                                <NewReportTeam
                                    team="teamSecond"
                                    category="replacement"
                                    teamPlayers={teamPlayers.teamSecond.replacement}
                                    setTeamPlayers={setTeamPlayers}
                                    playersFirstTeam={playersFirstTeam}
                                    playersSecondTeam={playersSecondTeam}
                                />
                            </div>

                            <div className="nr-body">
                                <h2 className="nr-body__title">Запасні</h2>
                                <NewReportTeam
                                    team="teamFirst"
                                    teamPlayers={teamPlayers.teamFirst.spare}
                                    setTeamPlayers={setTeamPlayers} category="spare" statusPlayer={false}

                                    playersFirstTeam={playersFirstTeam}
                                    playersSecondTeam={playersSecondTeam}

                                />
                                <NewReportTeam
                                    team="teamSecond"
                                    teamPlayers={teamPlayers.teamSecond.spare}
                                    setTeamPlayers={setTeamPlayers} category="spare" statusPlayer={false}

                                    playersFirstTeam={playersFirstTeam}
                                    playersSecondTeam={playersSecondTeam}

                                />
                            </div>


                        </div>

                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <FootballField screenShot={setScreenFirstField}/>
                            <FootballField screenShot={setScreenSecondField}/>
                        </div>
                    </div>

                    <div className="nr-wrapper"
                         style={{display: 'flex', alignItems: 'flex-start', gap: '30px', marginTop: '100px'}}>
                        <NewReportActions
                            allPlayers={playersFirstTeam.concat(playersSecondTeam)}
                            title="Загальні дії"
                            lines="action"
                            nameTeamFirst={nameTeamFirst}
                            nameTeamSecond={nameTeamSecond}


                        />
                    </div>

                    <div className="nr-wrapper"
                         style={{display: 'flex', alignItems: 'flex-start', gap: '30px', marginTop: '100px'}}>
                        <NewReportActions
                            allPlayers={playersFirstTeam.concat(playersSecondTeam)}
                            title="Рух мʼяча"
                            lines="ballMovement"
                            number={false}
                            goals={false}
                            assists={false}
                            intensity={false}
                            minutes={false}
                            firstTime={false}
                            secondTime={false}
                            firstTimeInt={false}
                            secondTimeInt={false}



                        />
                        <NewReportActions


                            allPlayers={playersFirstTeam.concat(playersSecondTeam)}
                            title="Воротарь"
                            lines="goalkeepers"
                            number={false}
                            goals={false}
                            assists={false}
                        />
                    </div>

                    <div className="nr-wrapper"
                         style={{display: 'flex', alignItems: 'flex-start', gap: '30px', marginTop: '100px'}}>
                        <NewReportActions
                            allPlayers={playersFirstTeam.concat(playersSecondTeam)}
                            title="Лінія захисту"
                            lines="defense"
                            number={false}
                            goals={false}
                            assists={false}
                            intensity={false}
                            minutes={false}
                            firstTime={false}
                            secondTime={false}
                            firstTimeInt={false}
                            secondTimeInt={false}
                        />
                        <NewReportActions
                            allPlayers={playersFirstTeam.concat(playersSecondTeam)}
                            title="Лінія півзахисту"
                            lines="midfield"
                            number={false}
                            goals={false}
                            assists={false}
                            intensity={false}
                            minutes={false}
                            firstTime={false}
                            secondTime={false}
                            firstTimeInt={false}
                            secondTimeInt={false}
                        />
                        <NewReportActions
                            allPlayers={playersFirstTeam.concat(playersSecondTeam)}
                            title="Нападники"
                            lines="attackers"
                            number={false}
                            goals={false}
                            assists={false}
                            intensity={false}
                            minutes={false}
                            firstTime={false}
                            secondTime={false}
                            firstTimeInt={false}
                            secondTimeInt={false}
                        />
                        <NewReportActions
                            allPlayers={playersFirstTeam.concat(playersSecondTeam)}
                            title="Заміни"
                            lines="replacementLine"
                            number={false}
                            goals={false}
                            assists={false}
                            intensity={false}
                            minutes={false}
                            firstTime={false}
                            secondTime={false}
                            firstTimeInt={false}
                            secondTimeInt={false}
                        />
                    </div>

                    <button className="btn" style={{margin: '100px auto 0'}}>
                        Створити звіт
                    </button>
                </div>
            </Form>
        </>
    );
};

export default CreateNewReport;