export const dateFormat = (value) => {
    const date = new Date(value);
    const year = date.toLocaleDateString("default", {year: "numeric"});
    const month = date.toLocaleDateString("default", {month: "2-digit"});
    const days = date.toLocaleDateString("default", {day: "2-digit"});

    return `${year}/${month}/${days}`;
}

export const timeFormat = (value) => {
    const date = new Date(value);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
}