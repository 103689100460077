export const chartOptions2 = (indicator, dataValue, radius) => {
    return  {
        color: ['#67F9D8', '#FFE434', '#56A3F1', '#FF917C'],
        legend: false,
        radar: [
            {
                indicator: indicator,
                center: ['50%', '50%'],
                radius: radius,
                startAngle: 90,
                splitNumber: 4,
                shape: 'circle',
                axisName: {
                    formatter: function (val, obj) {
                        return `${val},\n max: ${obj.max}`;
                    },
                    textAlign: 'center',
                    color: '#000'
                },
                splitArea: {
                    areaStyle: {
                        color: ['rgba(222, 222, 222, 0.3)', 'rgba(226, 226, 226, 0.3)', 'rgba(231, 231, 231, 0.3)', 'rgba(238, 238, 238, 0.3)'],
                        shadowColor: 'rgba(0, 0, 0, 0.2)',
                        shadowBlur: 10
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(0, 200, 255, 0.4)',
                        width: 1.5
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgba(0, 200, 255, 0.4)'
                    }
                }
            },
        ],
        series: [
            {
                type: 'radar',
                emphasis: {
                    lineStyle: {
                        width: 3,
                    },
                },
                data: [
                    {
                        value: dataValue,
                        name: 'Data A',
                        symbolSize: 6,
                        label: {
                            show: false,
                            formatter: function (params) {
                                return params.value;
                            },
                            color: '#000',
                            fontWeight: 600,
                        },
                        areaStyle: {
                            color: 'rgba(0, 200, 255, 0.4)'
                        },
                        lineStyle: {
                            width: 2,
                            color: '#00c8ff'
                        },
                        itemStyle: {
                            color: '#6fd4ff'
                        }
                    },
                ]
            }
        ]
    };
}