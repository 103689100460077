import React from 'react';
import {formatName} from "../../helpers/formatName";
import ReactECharts from 'echarts-for-react';
import {chartOptions} from "../../configs/charts.config";
import {chartOptions2} from "../../configs/charts2.config";
import { useLocation } from 'react-router-dom';

const StatisticAction = ({data, title, sum}) => {
    let sumAssist = 0;
    let sumIntensity = 0;
    let sumGoals = 0;

const filteredData = data.filter((el) => el.minutes > 0 || el.air > 0 || el.assist > 0 || el.coveringDepth > 0 || el.defPosition > 0 || el.duel > 0 || el.events > 0 || el.foul >0 || el.goals >0 || el.intensity > 0 || el.offTheBall > 0 || el.pressingDuel > 0 || el.shoots > 0  );

    const location = useLocation();
    return (
        <>
            <h3>{title}</h3>

            <div style={{display: 'flex' , position: "relative"}}>
                <ul className="statistic__list ">
                    <li className="statistic__item" style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                        <span style={{width: '145px'}}>Гравець</span>
                        <span style={{width: '35px'}} title="Зіграні хвилини">Зіг. хв.</span>
                        <span style={{width: '25px', textAlign: 'center'}}>Голи</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Асисти">Ас.</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Удари">Уд.</span>
                        <span style={{width: '30px', textAlign: 'center'}}>ТТД</span>
                        <span>ТТД %</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Інтенсивність">Інт.</span>
                        <span style={{width: '35px', textAlign: 'center'}} title="Загальні дуелі">Заг. д.</span>
                        <span style={{width: '30px', textAlign: 'center'}}>Дуелі %</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Верхові дуелі">Вер. д.</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Верхові дуелі %">Вер. д. %</span>
                        <span style={{width: '30px', textAlign: 'center'}}>Фоли</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Прискорення">При.</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Повернення">Пов.</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Закриття зони">Зак. з.</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Пресинг">Пр.</span>
                    </li>
                    {filteredData.map((data, index) =>

                        <li key={index} className="statistic__item pdf">
                            <div style={{display: 'none'}}>
                                {sumAssist += +data.assist}
                                {sumIntensity += +data.intensity}
                                {sumGoals += +data.goals}
                            </div>

                        <span style={{width: '130px', textAlign: 'left', display: 'flex', gap: '10px'}}>
                            {data.number}
                            <span title={data.name}>{formatName(data.name)}</span>
                        </span>
                            <span style={{width: '35px', textAlign: 'center'}}>{data.minutes}</span>
                            <span style={{width: '15px', textAlign: 'center'}}>{data.goals}</span>
                            <span style={{width: '20px', textAlign: 'center'}}>{data.assist}</span>
                            <span style={{width: '20px', textAlign: 'center'}}>{data.shoots}</span>
                            <span style={{width: '20px', textAlign: 'center'}}>{data.events}</span>
                            <span style={{width: '20px', textAlign: 'center'}}>{data.eventsSuccess}</span>
                            <span style={{width: '25px', textAlign: 'center'}}>{data.intensity}</span>
                            <span style={{width: '20px', textAlign: 'center'}}>{data.duel}</span>
                            <span style={{width: '20px', textAlign: 'center'}}>{data['duel %']}</span>
                            <span style={{width: '15px', textAlign: 'center'}}>{data.air}</span>
                            <span style={{width: '30px', textAlign: 'center'}}>{data['air %']}</span>
                            <span style={{width: '15px', textAlign: 'center'}}>{data.foul}</span>
                            <span style={{width: '20px', textAlign: 'center'}}>{data.offTheBall}</span>
                            <span style={{width: '20px', textAlign: 'center'}}>{data.defPosition}</span>
                            <span style={{width: '20px', textAlign: 'center'}}>{data.coveringDepth}</span>
                            <span style={{width: '20px', textAlign: 'center'}}>{data.pressingDuel}</span>
                        </li>
                    )}
                    <li className="statistic__item">
                        <span style={{width: '130px', textAlign: 'left', display: 'flex', gap: '10px'}}>
                            Сума:
                        </span>
                        <span style={{width: '35px', textAlign: 'center'}}>-</span>
                        <span style={{width: '15px', textAlign: 'center'}}>{sumGoals}</span>
                        <span style={{width: '20px', textAlign: 'center'}}>{sumAssist}</span>
                        <span style={{width: '20px', textAlign: 'center'}}>{sum.shoots}</span>
                        <span style={{width: '20px', textAlign: 'center'}}>{sum.events}</span>
                        <span style={{width: '20px', textAlign: 'center'}}>{sum.eventsSuccess}</span>
                        <span style={{width: '25px', textAlign: 'center'}}>{sumIntensity ? sumIntensity.toFixed(2) : 0 }</span>
                        <span style={{width: '20px', textAlign: 'center'}}>{sum.duel}</span>
                        <span style={{width: '20px', textAlign: 'center'}}>{sum['duel %']}</span>
                        <span style={{width: '15px', textAlign: 'center'}}>{sum.air}</span>
                        <span style={{width: '30px', textAlign: 'center'}}>{sum['air %']}</span>
                        <span style={{width: '15px', textAlign: 'center'}}>{sum.foul}</span>
                        <span style={{width: '20px', textAlign: 'center'}}>{sum.offTheBall}</span>
                        <span style={{width: '20px', textAlign: 'center'}}>{sum.defPosition}</span>
                        <span style={{width: '20px', textAlign: 'center'}}>{sum.coveringDepth}</span>
                        <span style={{width: '20px', textAlign: 'center'}}>{sum.pressingDuel}</span>
                    </li>
                </ul>
                {location.pathname === "/new-report-info" ?  <ReactECharts
                    style={{ width: '620px', height: '600px', margin: '0 auto'}}
                    option={chartOptions([
                        { text: `ТТД: ${sum.events}`, max: 1200 },
                        { text: `ТТД %: ${sum['events %']}`, max: 100 },
                        { text: `Інт.: ${sumIntensity ? sumIntensity.toFixed(2) : 0}`, max: 15 },
                        { text: `Загальні\n дуелі: ${sum.duel}`, max: 200 },
                        { text: `Дуелі %: ${sum['duel %']}`, max: 100 },
                        { text: `Верхові\n дуелі: ${sum.air}`, max: 100 },
                        { text: `Верхові\n дуелі %: ${sum['air %']}`, max: 100 },
                        { text: `Прискорення: ${sum.offTheBall}`, max: 120 },
                        { text: `Повернення: ${sum.defPosition}`, max: 120 },
                        { text: `Закриття зони: ${sum.coveringDepth}`, max: 50 },
                        { text: `Пресинг: ${sum.pressingDuel}`, max: 100 },
                    ], [
                        sum.events,
                        sum['events %'],
                        sumIntensity.toFixed(2),
                        sum.duel,
                        sum['duel %'],
                        sum.air,
                        sum['air %'],
                        sum.offTheBall,
                        sum.defPosition,
                        sum.coveringDepth,
                        sum.pressingDuel
                    ], 200)}
                /> :  <ReactECharts
                    style={{ width: '550px', height: '550px', margin: '0 auto', position: "absolute", top: "550px", right: "200px"}}
                    option={chartOptions2( [
                        { text: `ТТД: ${sum.events}`, max: 1200 },
                        { text: `ТТД %: ${sum['events %']}`, max: 100 },
                        { text: `Інт.: ${sumIntensity.toFixed(2)}`, max: 15 },
                        { text: `Загальні\n дуелі: ${sum.duel}`, max: 200 },
                        { text: `Дуелі %: ${sum['duel %']}`, max: 100 },
                        { text: `Верхові\n дуелі: ${sum.air}`, max: 100 },
                        { text: `Верхові\n дуелі %: ${sum['air %']}`, max: 100 },
                        { text: `Прискорення: ${sum.offTheBall}`, max: 120 },
                        { text: `Повернення: ${sum.defPosition}`, max: 120 },
                        { text: `Закриття зони: ${sum.coveringDepth}`, max: 50 },
                        { text: `Пресинг: ${sum.pressingDuel}`, max: 100 },
                    ], [
                        sum.events,
                        sum['events %'],
                        sumIntensity.toFixed(2),
                        sum.duel,
                        sum['duel %'],
                        sum.air,
                        sum['air %'],
                        sum.offTheBall,
                        sum.defPosition,
                        sum.coveringDepth,
                        sum.pressingDuel
                    ], 200)}
                /> }

            </div>

        </>
    );
};

export default StatisticAction;