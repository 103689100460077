import {Input, message, Modal} from 'antd';
import {useEffect, useState} from "react";
import {DeleteFilled, EditFilled, ExclamationCircleFilled, SnippetsFilled} from "@ant-design/icons";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {
    changeTabProps,
    currentReport,
    getReports,
    toggleLoading,
    typeReport
} from "../../store/slices/analyticsSlice";
import {getCurrentNewReport, getNewReports} from "../../store/slices/newReportSlice";
import ModalCreateOldReport from "../Modal/ModalCreateOldReport";
import ModalUpdateOldReport from "../Modal/ModalUpdateOldReport";
import {Link, useNavigate} from "react-router-dom";


const {Search} = Input;
const {confirm} = Modal;

const Analytics = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tabProps = useSelector(state => state.analytics.tabProps);
    const data = useSelector(state => state.analytics.data);
    const newReportData = useSelector(state => state.newReport.newReport);
    const toggleLoad = useSelector(state => state.analytics.isLoading);
    const typeRep = useSelector(state => state.analytics.typeReport);
    const {role} = useSelector(state => state.user.userInfo);

    const [isLoading, setIsLoading] = useState(true);
    const [modalOldReport, setModalOldReport] = useState(false);
    const [modalUpdateOldReport, setModalUpdateOldReport] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [messageApi, contextHolder] = message.useMessage();

    const displayMessage = (type, content) => {
        messageApi.open({
            style: {textAlign: 'center'},
            type,
            content,
        });
    };

    useEffect(() => {
        dispatch(getReports([]));

        if (tabProps === 'old-analytics') {
            setIsLoading(true);
            axios.get(`${process.env.REACT_APP_SERVER_URL}api/reports/old/get`, {
                headers: {"Authorization": document.cookie}
            })
                .then(res => {
                    dispatch(getReports(res.data.data[typeRep]));
                    setIsLoading(false);
                })
                .catch(error => console.error(error))
        }

        if (tabProps === 'new-analytics') {
            setIsLoading(true);
            axios.get(`${process.env.REACT_APP_SERVER_URL}api/reports/get`, {
                headers: {"Authorization": document.cookie}
            })
                .then(res => {
                    dispatch(getNewReports(res.data.data[typeRep]));
                    setIsLoading(false);
                })
                .catch(error => console.error(error))
        }

    }, [dispatch, tabProps, toggleLoad, typeRep])

    const filteredReports = data.filter((data) =>
        data.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    const filteredNewReports = newReportData.filter((data) =>
        data.client.toLowerCase().includes(searchValue.toLowerCase())
    );

    const deleteReport = (id) => {
        confirm({
            title: `Видалити доклад?`,
            icon: <ExclamationCircleFilled/>,
            cancelText: 'Відмінити',
            okText: 'Видалити',
            onOk() {
                axios({
                    method: 'DELETE',
                    url: `${process.env.REACT_APP_SERVER_URL}api/reports/old/delete`,
                    headers: {"Authorization": document.cookie},
                    data: {id: id}
                }).then(({status}) => {
                    if (status === 200) {
                        dispatch(toggleLoading());
                        displayMessage('success', 'Доклад видалено!');
                    }
                }).catch(err => console.error(err));
            },
        })
    }
    const deleteReportNew = (id) => {
        confirm({
             title: `Видалити доклад?`,
             icon: <ExclamationCircleFilled/>,
             cancelText: 'Відмінити',
             okText: 'Видалити',
             onOk() {
                 axios({
                     method: 'DELETE',
                     url: `${process.env.REACT_APP_SERVER_URL}api/reports/delete?reportId=${id}`,
                     headers: {"Authorization": document.cookie},
                     data: {id: id}
                }).then(({status}) => {
                     if (status === 200) {
                         dispatch(toggleLoading());
                         displayMessage('success', 'Доклад видалено!');
                     }
                 }).catch(err => console.error(err));
             },
         })
     }

    const getNewReportsInfo = (id) => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}api/reports/get-report?reportId=${id}`, {
            headers: {"Authorization": document.cookie}
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(getCurrentNewReport(res.data.data));
                    navigate('/new-report-info');
                }
            })
            .catch(error => console.error(error))

    }

    console.log(tabProps)

    return (
        <div className="container pb-container">
            {contextHolder}
            <div className="head-title">
                <div className="title">Всі матчі</div>
                <div className="tabs">
                    <button
                        className={tabProps === 'old-analytics' ? 'is-active' : ''}
                        onClick={() => {
                            dispatch(changeTabProps('old-analytics'));
                            dispatch(typeReport('kolos'));
                        }}
                    >СТАРА АНАЛІТИКА
                    </button>
                    <button
                        className={tabProps === 'new-analytics' ? 'is-active' : ''}
                        onClick={() => {
                            dispatch(changeTabProps('new-analytics'));
                            dispatch(typeReport('kolos'));
                        }}
                    >НОВА АНАЛІТИКА
                    </button>
                </div>

                {role !== 'user' &&
                    <>
                        {tabProps === 'old-analytics' &&
                            <button className="analytics-button" onClick={() => setModalOldReport(true)}>
                                <SnippetsFilled/> ЗАВАНТАЖИТИ СТАРИЙ ДОКЛАД
                            </button>
                        }

                        {tabProps === 'new-analytics' &&
                            <Link to="/create-new-report" className="analytics-button">
                                <button
                                    className="analytics-button"
                                >
                                    <SnippetsFilled/> СТВОРИТИ НОВИЙ ДОКЛАД
                                </button>
                            </Link>
                        }
                    </>
                }

                <Search
                    className="search-custom"
                    placeholder="Пошук..."
                    allowClear
                    size="large"
                    style={{width: 350}}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </div>


            <div className="reports-toggle-type">
                <button
                    className={typeRep === 'kolos' ? 'is-active' : ''}
                    onClick={() => {
                        dispatch(typeReport('kolos'))
                    }}
                >
                    kolos
                </button>
                <button
                    className={typeRep === 'kolos_u19' ? 'is-active' : ''}
                    onClick={() => {
                        dispatch(typeReport('kolos_u19'))
                    }}
                >
                    kolos_u19
                </button>
                <button
                    className={typeRep === 'scouting' ? 'is-active' : ''}
                    onClick={() => {
                        dispatch(typeReport('scouting'))
                    }}
                >
                    scouting
                </button>
            </div>


            <ul className="league-list">
                {tabProps === 'old-analytics' && !isLoading &&
                    filteredReports.reverse().map(({name, date, url, _id, type}, index) =>
                        <li key={index} className="league-item league-item--mod">
                            <a
                                href={`${process.env.REACT_APP_SERVER_URL}${url}`}
                                className="league-cart league-cart--mod"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <h2>{name}</h2>
                                <p>{new Date(date).toLocaleDateString()}</p>
                            </a>

                            {role !== 'user' &&
                                <div className="league-item__buttons">
                                    <button
                                        className="league-item__btn"
                                        onClick={() => {
                                            setModalUpdateOldReport(true);
                                            dispatch(currentReport({_id, name, date, url, type}));
                                        }}
                                    >
                                        <EditFilled style={{color: 'green'}}/>
                                    </button>
                                    <button
                                        className="league-item__btn"
                                        onClick={() => deleteReport(_id)}
                                    >
                                        <DeleteFilled style={{color: 'red'}}/>
                                    </button>
                                </div>
                            }
                        </li>
                    )
                }

                {tabProps === 'new-analytics' && !isLoading &&
                    filteredNewReports.reverse().map(({_id, client, dateMatch, leagueName, tour, holdingTime}, index) =>
                    <li key={index} className="league-item league-item--mod">
                        <div
                            className="league-cart league-cart--mod"
                            onClick={() => getNewReportsInfo(_id)}
                        >
                            <h2>клієнт: {client}</h2>
                            <p>Дата матчу: {new Date(dateMatch).toLocaleDateString()}</p>
                            <p>Ліга: {leagueName}</p>
                            <p>Тур: {tour}</p>
                            <p>Час проведення: {holdingTime}</p>
                        </div>

                        {role !== 'user' &&
                            <div className="league-item__buttons">
                                <button
                                    className="league-item__btn"
                                    // onClick={() => deleteReport(_id)}
                                    onClick={() => deleteReportNew(_id)}

                                >
                                    <DeleteFilled style={{color: 'red'}}/>
                                </button>
                            </div>
                        }
                    </li>
                )}

                {isLoading &&
                    <div className="preloader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                }

                {!filteredReports.length && searchValue ?
                    <div className="not-found">За вашим запитом нічого не знайдено!</div> : null}
            </ul>

            <ModalCreateOldReport modalOldReport={modalOldReport} setModalOldReport={setModalOldReport}/>
            <ModalUpdateOldReport modalUpdateOldReport={modalUpdateOldReport}
                                  setModalUpdateOldReport={setModalUpdateOldReport}/>

        </div>
    );
};

export default Analytics;