import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    match: '',
    isLoading: true,
};

const loadMatchSlice = createSlice({
    name: "loadMatch",
    initialState,
    reducers: {
        load: (state, action) => {
            state.match = action.payload;
        }
    },
});

export const { load } = loadMatchSlice.actions;

export default loadMatchSlice.reducer;