import {useState} from 'react';
import {Button, Form, Input, Select, Modal, message} from "antd";
import axios from "axios";
import {useDispatch} from "react-redux";
import {renderList} from "../../store/slices/userSlice";

const ModalCreateUser = ({modalCreateUser, setModalCreateUser}) => {
    const dispatch = useDispatch();

    const [messageApi, contextHolder] = message.useMessage();
    const [userInfo, setUserInfo] = useState(
        {
            name: '',
            email: '',
            password: '',
            role: 'user'
        });

    const success = () => {
        messageApi.open({
            style: {
                textAlign: 'center',
            },
            type: 'success',
            content: 'Користувача створено!',
        });
    };

    const createUser = (values) => {
        const data = {...values}

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_URL}api/user/create`,
            headers: {"Authorization": document.cookie},
            data: data
        })
            .then(res => {
                if (res.status === 200) success();
                setModalCreateUser(false);
                dispatch(renderList());
            })
            .catch(error => console.error(error));

    }

    return (
        <>
            {contextHolder}
            <Modal
                title="Створити користувача"
                width="350px"
                open={modalCreateUser === true}
                onCancel={() => {
                    setModalCreateUser(false);
                    setUserInfo({
                        name: '',
                        email: '',
                        password: '',
                        role: 'user'
                    })
                }}
                footer={null}
                destroyOnClose
            >
                <Form
                    name="createUserForm"
                    autoComplete="off"
                    onFinish={createUser}
                >
                    <Form.Item
                        name="name"
                        style={{margin: '20px 0 0'}}
                        rules={[
                            {
                                required: true,
                                min: 4,
                                message: 'Мінімальна довжина 4 літери!'
                            }
                        ]}
                    >
                        <Input
                            value={userInfo.name}
                            placeholder="Ім'я користувача *"
                            size="large"
                            onInput={(e) => setUserInfo({...userInfo, name: e.target.value})}
                        />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        style={{margin: '15px 0 0'}}
                        rules={[
                            {
                                required: true,
                                message: 'Невірно введено E-mail!',
                                pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                            }
                        ]}
                    >
                        <Input
                            placeholder="Пошта користувача *"
                            size="large"
                            onInput={(e) => setUserInfo({...userInfo, email: e.target.value})}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        style={{margin: '15px 0 0'}}
                        rules={[
                            {
                                required: true,
                                min: 6,
                                message: 'Мінімальна довжина паролю 6 символів!'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Пароль *"
                            size="large"
                            onInput={(e) => setUserInfo({...userInfo, password: e.target.value})}
                        />
                    </Form.Item>

                    <Form.Item
                        name="role"
                        initialValue='user'
                    >
                        <Select
                            style={{ width: '100%', margin: '15px 0 15px' }}
                            size="large"
                            onChange={(value) => setUserInfo({...userInfo, role: value})}
                            options={[
                                { value: 'user', label: 'Користувач' },
                                { value: 'admin', label: 'Адміністратор' },
                                { value: 'moderator', label: 'Модератор' },
                            ]}
                        />
                    </Form.Item>

                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        style={{width: '100%'}}
                    >
                        Створити
                    </Button>
                </Form>
            </Modal>
        </>

    );
};

export default ModalCreateUser;