import React from 'react';
import {DatePicker, Form, Input, TimePicker} from "antd";
import locale from "antd/es/date-picker/locale/uk_UA";
import styles from "../Teams/Teams.module.css";
import {CloseCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

const NewReportHeader = ({setImages, images}) => {

    const imgUploadHandler = (e, imgIndex) => {
        const file = e.target.files[0];
        setImages((prevImages) => ({...prevImages, [imgIndex]: file}));
    };

    const clearImg = (imgIndex) => {
        setImages((prevImages) => ({...prevImages, [imgIndex]: null}));
    };

    const imageElements = ["firstImg", "secondImg"].map((imgIndex) => (
        <div
            key={imgIndex}
            className={images[imgIndex] ? styles.uploaded : ''}
            style={{marginBottom: '15px'}}
        >
            <input
                type="file"
                name={imgIndex}
                accept=".png,.jpg,.jpeg"
                className={styles.inputFile}
                id={`${imgIndex}Image`}
                onChange={(e) => imgUploadHandler(e, imgIndex)}
            />
            <label htmlFor={`${imgIndex}Image`} className={styles.labelFile}>
                <PlusCircleOutlined className={styles.labelFileIcon}/>
            </label>
            {images[imgIndex] && (
                <span className={styles.uploadedImg}>
                    <img src={URL.createObjectURL(images[imgIndex])} alt={`${imgIndex}_img`}/>
                    <button
                        className={styles.imgRemove}
                        onClick={() => clearImg(imgIndex)}
                    >
                        <CloseCircleOutlined/>
                    </button>
                </span>
            )}
        </div>
    ));

    return (
        <div className="nr-header">
            <div className="nr-header__left">
                <Form.Item
                    name="client"
                    style={{marginBottom: '0'}}
                    rules={[
                        {
                            required: true,
                            message: ''
                        }
                    ]}
                >
                    <Input
                        className="nr-header__logo"
                        placeholder="Клієнт"
                    />
                </Form.Item>
                <div className="nr-header__info">
                    <span>Звіт по матчу</span>
                    <Form.Item
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                    >
                        <Input
                            className="input-custom"
                            placeholder="Заголовок"
                        />
                    </Form.Item>
                    <Form.Item
                        name="nameTour"
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                    >
                        <Input
                            className="input-custom"
                            placeholder="Назва туру"
                        />
                    </Form.Item>
                    <Form.Item
                        name="dateMatch"
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                    >
                        <DatePicker
                            className="input-custom"
                            placeholder="Дата матчу"
                            locale={locale}
                            style={{width: '100%'}}
                        />
                    </Form.Item>
                    <Form.Item
                        name="holdingTime"
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                    >
                        <TimePicker
                            popupClassName="input-custom-popup"
                            className="input-custom"
                            placeholder="Час проведення"
                            format='HH:mm'
                            style={{width: '100%'}}
                        />
                    </Form.Item>
                </div>

            </div>
            <div className="nr-header__right">
                <div className="nr-header__upload-photos">{imageElements}</div>
                <Form.Item
                    name="leagueName"
                    rules={[
                        {
                            required: true,
                            message: ''
                        }
                    ]}
                >
                    <Input
                        className="input-custom"
                        placeholder="Назва ліги"
                    />
                </Form.Item>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Form.Item
                        name="teamNameFirst"
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                        style={{width: '150px'}}
                    >
                        <Input
                            className="input-custom"
                            placeholder="Перша команда"
                        />
                    </Form.Item>
                    <Form.Item
                        name="teamNameSecond"
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                        style={{width: '150px'}}
                    >
                        <Input
                            className="input-custom"
                            placeholder="Друга команда"
                        />
                    </Form.Item>
                </div>
            </div>
        </div>
    );
};

export default NewReportHeader;