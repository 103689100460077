import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Header from "../components/Header/Header";
import {CloseCircleOutlined, LeftOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, message, Upload} from 'antd';
import {Switch} from 'antd';
import CodeMirror from '@uiw/react-codemirror';
import {load} from "../store/slices/loadMatchSlice";

import axios from "axios";

const LoadingMatch = () => {
    const dispatch = useDispatch();
    const loadMatch = useSelector(state => state.loadMatch.match)
    const [chk, setChk] = useState(true);
    const [checkResult, setCheckResult] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const displayMessage = (type, content) => {
        messageApi.open({
            style: { textAlign: 'center' },
            type,
            content,
        });
    };

    const sendMatch = async () => {
        try {
            const data = {
                save: chk,
                data: JSON.parse(loadMatch),
            };

            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/analytics/load`, data, {
                headers: { Authorization: document.cookie },
            });

            if (response.status === 200) {
                dispatch(load(JSON.stringify(response.data.data, null, '\t')));
                setCheckResult(true);
                displayMessage('success', 'Матч завантажено!');
            }
        } catch (err) {
            displayMessage('error', err.response?.data.message === 'Such a match already exists in the database!' ? 'Цей матч уже існує!' : 'Недійсний формат json!');
        }
    };

    const backTo = () => {
        dispatch(load(''));
        setChk(true);
        setCheckResult(false);
    };

    const onFileUpload = (file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            dispatch(load(e.target.result));
        };

        reader.readAsText(file);
        return false;
    };

    return (
        <>
            {contextHolder}
            <Header/>
            <div style={{width: '100%', margin: '30px auto 0', padding: '0 30px'}}>
                {!checkResult ?
                    <div style={{display: 'flex', alignItems: 'center', gap: '40px'}}>
                        <Upload
                            className="upload-custom"
                            accept=".json, .JSON"
                            showUploadList={false}
                            beforeUpload={onFileUpload}
                        >
                            <Button>
                                <UploadOutlined/> Загрузити json
                            </Button>
                        </Upload>

                        <Switch
                            checkedChildren="Зберегти"
                            unCheckedChildren="Не зберігати"
                            checked={chk}
                            onChange={() => setChk(!chk)}
                        />
                    </div>
                    : <button className="btn-back-match" onClick={backTo}><LeftOutlined /> Назад</button>
                }

                <div style={{position: 'relative'}}>
                    <CodeMirror
                        className={checkResult ? 'readOnly': ''}
                        value={loadMatch}
                        readOnly={checkResult}
                        onChange={(editor ) => dispatch(load(editor))}
                        style={{
                            height: 'calc(100vh - 225px)',
                            overflow: 'auto',
                            borderRadius: '15px',
                            border: !checkResult ? '1px solid #00c8ff' : '1px solid darkgreen',
                            marginTop: '30px',
                            fontSize: 15,
                            backgroundColor: "rgba(22, 27, 34, 0.5)",
                        }}
                        options={{
                            mode: 'json',
                            readOnly: true
                        }}/>

                    {
                        loadMatch && !checkResult &&
                            <button
                                className="btn"
                                style={{position: 'absolute', right: '20px', bottom: '0'}}
                                onClick={sendMatch}
                            >
                                Загрузити
                            </button>
                    }

                    {
                        loadMatch && !checkResult &&
                            <CloseCircleOutlined
                                style={{position: 'absolute', right: '15px', top: '15px'}}
                                onClick={() => dispatch(load(''))}
                            />
                    }
                </div>

            </div>

        </>
    );
};

export default LoadingMatch;