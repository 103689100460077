import Header from "../components/Header/Header";
import {Navigate} from "react-router-dom";
import Analytics from "../components/Analytics/Analytics";

const Home = () => {
    if (document.cookie.length === 0) {
        return <Navigate to="/login" />
    }

    return (
        <>
            <Header />
            <Analytics />
        </>
    );
};

export default Home;