import React from 'react';
import {Checkbox, Form, Input, InputNumber, Select} from "antd";

const NewReportPlayer = (
    {
        team,
        player,
        category,
        positionPlayer,
        numberPlayer,
        namePlayer,
        statusPlayer,
        goalsPlayer,
        playerOptions,
        replacementTime
    }
) => {

    const chkOptions = [
        // {label: null, value: "goal"},
        {label: null, value: "redCard"},
        {label: null, value: "yellowCard"},
    ];

    return (
        <div className="nr-body__item">
            {positionPlayer &&
                <Form.Item
                    name={[team, category, player, "position"]}
                    rules={[
                        {
                            required: true,
                            message: ''
                        }
                    ]}
                >
                    <Select
                        className="select-custom"
                        style={{width: '95px', margin: '0'}}
                        placeholder="Позиція"
                        options={[
                            {value: 'GK', label: 'GK'},
                            {value: 'CB', label: 'CB'},
                            {value: 'LB', label: 'LB'},
                            {value: 'RB', label: 'RB'},
                            {value: 'LWB', label: 'LWB'},
                            {value: 'RWB', label: 'RWB'},
                            {value: 'CM', label: 'CM'},
                            {value: 'LM', label: 'LM'},
                            {value: 'RM', label: 'RM'},
                            {value: 'CAM', label: 'CAM'},
                            {value: 'ST', label: 'ST'},
                            {value: 'CF', label: 'CF'},
                            {value: 'LW', label: 'LW'},
                            {value: 'RW', label: 'RW'},
                        ]}
                    />
                </Form.Item>
            }

            {numberPlayer &&
                <Form.Item
                    name={[team, category, player, "number"]}
                    rules={[
                        {
                            required: true,
                            message: ''
                        }
                    ]}
                >
                    <InputNumber
                        className="input-custom"
                        placeholder="№"
                        min={1}
                        max={99}
                        style={{width: '55px', margin: '0'}}
                        step={1}
                    />
                </Form.Item>
            }

            {namePlayer &&
                <Form.Item
                    name={[team, category, player, "id"]}
                    rules={[
                        {
                            required: true,
                            message: ''
                        }
                    ]}
                >
                    <Select
                        className="select-custom"
                        showSearch
                        style={{width: '195px', margin: '0'}}
                        placeholder="Гравeць"
                        options={playerOptions}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        notFoundContent={'Гравця не знайдено!'}
                    />
                </Form.Item>
            }

            {replacementTime &&
                <Form.Item
                    name={[team, category, player, "replacementTime"]}
                    style={{marginLeft: 'auto'}}
                >
                    <Input
                        className="input-custom"
                        style={{width: '25px', padding: '3px'}}
                        maxLength={2}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Form.Item>
            }




            {statusPlayer &&
                <>
                <Form.Item
                    name={[team, category, player, 'goalsPlayer']}
                    rules={[
                        {
                            // required: true,
                            message: ''
                        }
                    ]}
                >
                    <Input
                        className="input-custom"
                        style={{width: '15px', padding: '3px', textAlign: 'center'}}
                        maxLength={2}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item name={[team, category, player, "status"]}>




                    <Checkbox.Group className="chk-custom" options={chkOptions}/>
                </Form.Item>
                </>
            }
            {!statusPlayer &&  <Form.Item name={[team, category, player, "status"]}>




                <Checkbox.Group className="chk-custom" options={chkOptions}/>
            </Form.Item>}

        </div>
    );
};

export default NewReportPlayer;