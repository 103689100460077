import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    tabProps: 'old-analytics',
    typeReport: 'kolos' || 'scouting' || 'kolos_u19',
    isLoading: true,
    report: {},
};

const analyticsSlice = createSlice({
    name: "analytics",
    initialState,
    reducers: {
        getReports: (state, action) => {
            state.data = action.payload;
        },
        changeTabProps: (state, action) => {
            state.tabProps = action.payload;
        },
        toggleLoading: (state) => {
            state.isLoading = !state.isLoading;
        },
        currentReport: (state, action) => {
            state.report = action.payload;
        },
        typeReport: (state, action) => {
            state.typeReport = action.payload;
        }
    }
});

export const { getReports, changeTabProps, toggleLoading, currentReport, typeReport } = analyticsSlice.actions;

export default analyticsSlice.reducer;