import { configureStore } from '@reduxjs/toolkit';
import user from './slices/userSlice';
import loadMatch from './slices/loadMatchSlice';
import analytics from './slices/analyticsSlice';
import newReport from './slices/newReportSlice'

export const store = configureStore({
    reducer: {
        user,
        loadMatch,
        analytics,
        newReport
    },
})