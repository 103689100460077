import React, {useState} from 'react';
import {Form, Input, InputNumber, Select,  } from "antd";
import {FieldTimeOutlined, UserDeleteOutlined} from "@ant-design/icons";
import {ReactComponent as Ball} from "../../assets/images/ball.svg";



const NewReportActions = (
    {
        allPlayers,
        title,
        lines,
        number = true,
        player = true,
        assists = true,
        minutes = true,
        goals = true,
        intensity = true,
        firstTime = true,
        secondTime = true,
        firstTimeInt = true,
        secondTimeInt = true,

    }) => {


    const optionAllPlayers = allPlayers.map(({name, _id}) => {
        return {value: _id, label: name};
    });









    const [actions, setActions] = useState([{key: Date.now() + 1}]);

    const addPlayer = (e) => {
        e.preventDefault();
        setActions([...actions, { key: Date.now() + 1 }]);
    };

    const removePlayer = (e, actionKey) => {
        e.preventDefault();
        const filteredArray = actions.filter((action) => action.key !== actionKey);
        setActions(filteredArray);
    };

    const handleSelectChange = (value, actionKey) => {
        const updatedActions = actions.map((action) =>
            action.key === actionKey ? { ...action, id: value } : action
        );

        setActions(updatedActions);
    };

    const players = actions.map((action, index) => (
        <div key={action.key} className="statistic-item" style={{ display: 'flex', gap: '0 15px' }}>
            {number &&
                <Form.Item
                    name={[lines, `${action.key}`, 'number']}
                    rules={[
                        {
                            required: true,
                            message: ''
                        }
                    ]}
                >
                    <InputNumber
                        className="input-custom"
                        placeholder="№"
                        min={1}
                        max={99}
                        style={{width: '95px', margin: '0'}}
                        step={1}
                    />
                </Form.Item>
            }


            {player &&

                <Form.Item
                    name={[lines, `${action.key}`, 'id']}
                    rules={[
                        {
                            required: true,
                            message: ""
                        }
                    ]}
                    style={{width: '195px'}}
                >
                    <Select
                        name={[lines, 'id']}
                        className="select-custom"
                        showSearch
                        style={{width: '195px', margin: '0'}}
                        placeholder="гравець"
                        options={optionAllPlayers}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                         filterSort={(optionA, optionB) =>
                           (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                         }
                        notFoundContent={'Гравця не знайдено!'}

                        value={action.value}
                        onChange={(value) => handleSelectChange(value, action.key)}

                    >


                    </Select>

                </Form.Item>


            }






            {assists &&
                <Form.Item
                    name={[lines, `${action.key}`, 'assist']}
                    rules={[
                        {

                            message: ''
                        }
                    ]}
                >
                    <Input
                        className="input-custom"
                        style={{width: '80px'}}
                    />
                </Form.Item>
            }

            {minutes &&
                <Form.Item
                    name={[lines, `${action.key}`, 'minutes']}
                >
                    <Input
                        className="input-custom"
                        style={{width: '30px', padding: '3px', textAlign: 'center'}}
                        maxLength={2}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Form.Item>
            }

            {firstTime &&
                <Form.Item
                    name={[lines, `${action.key}`, 'firstTimeMatch']}
                >
                    <Input
                        className="input-custom"
                        style={{width: '30px', padding: '3px', textAlign: 'center'}}
                        maxLength={2}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Form.Item>
            }

            {secondTime &&
                <Form.Item
                    name={[lines, `${action.key}`, 'secondTimeMatch']}
                >
                    <Input
                        className="input-custom"
                        style={{width: '30px', padding: '3px', textAlign: 'center'}}
                        maxLength={2}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Form.Item>
            }

            {goals &&
                <Form.Item
                    name={[lines, `${action.key}`, 'goals']}
                >
                    <Input
                        className="input-custom"
                        style={{width: '30px', padding: '3px', textAlign: 'center'}}
                        maxLength={2}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Form.Item>
            }

            {intensity &&
                <Form.Item
                    name={[lines, `${action.key}`, 'intensity']}
                >
                    <Input
                        className="input-custom"
                        style={{width: '80px'}}
                    />
                </Form.Item>
            }
            {firstTimeInt &&
                <Form.Item
                    name={[lines, `${action.key}`, 'firstTimeMatchInt']}
                >
                    <Input
                        className="input-custom"
                        style={{width: '30px', padding: '3px', textAlign: 'center', marginLeft: "55px"}}
                        maxLength={2}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Form.Item>
            }
            {secondTimeInt &&
                <Form.Item
                    name={[lines, `${action.key}`, 'secondTimeMatchInt']}
                >
                    <Input
                        className="input-custom"
                        style={{width: '30px', padding: '3px', textAlign: 'center'}}
                        maxLength={2}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Form.Item>
            }

            <button
                className="nr-btn nr-btn--remove"
                onClick={(e) => removePlayer(e, action.key)}
                style={{display: 'flex', marginTop: '5px'}}
            >
                <UserDeleteOutlined/>
            </button>
        </div>
    ));


    return (
        <div className="nr-body">
            <h2 className="nr-body__title">{title}</h2>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div className="nr-body__titles">
                    {number && <div style={{width: '95px'}}>Номер</div>}
                    {player && <div style={{width: '195px'}}>Імʼя гравця</div>}
                    {assists && <div style={{width: '80px'}}>Асисти</div>}
                    {minutes && <div style={{width: '30px', minWidth: '30px'}}><FieldTimeOutlined style={{fontSize: '22px', height: '22px', display: 'flex', justifyContent: 'center'}} /></div>}
                    {firstTime && <div style={{width: '30px', minWidth: '30px', position: 'relative'}}><span style={{position: 'absolute', fontSize: '10px', bottom: 'calc(100% + 3px)', textAlign: 'center', width: '30px'}}>1-й</span><FieldTimeOutlined style={{fontSize: '22px', height: '22px', display: 'flex', justifyContent: 'center'}} /></div>}
                    {secondTime && <div style={{width: '30px', minWidth: '30px', position: 'relative'}}><span style={{position: 'absolute', fontSize: '10px', bottom: 'calc(100% + 3px)', textAlign: 'center', width: '30px'}}>2-й</span><FieldTimeOutlined style={{fontSize: '22px', height: '22px', display: 'flex', justifyContent: 'center'}} /></div>}
                    {goals && <div style={{width: '30px'}}><Ball style={{width: '22px', height: '22px', display: 'flex', margin: '0 auto'}}/></div>}
                    {intensity && <div className="intensity" style={{width: '140px'}}>Інтенсивтість</div>}

                    {firstTime && <div className="icon-firsttime" style={{ width: '30px', minWidth: '30px', position: 'relative'}}><span style={{position: 'absolute', fontSize: '10px', bottom: 'calc(100% + 3px)', textAlign: 'center', width: '30px'}}>1-й</span><FieldTimeOutlined style={{fontSize: '22px', height: '22px', display: 'flex', justifyContent: 'center'}} /></div>}
                    {secondTime && <div style={{width: '30px', minWidth: '30px', position: 'relative'}}><span style={{position: 'absolute', fontSize: '10px', bottom: 'calc(100% + 3px)', textAlign: 'center', width: '30px'}}>2-й</span><FieldTimeOutlined style={{fontSize: '22px', height: '22px', display: 'flex', justifyContent: 'center'}} /></div>}
                </div>

                {players}

                <button className="btn nr-btn--add" onClick={addPlayer}>додати гравця</button>

            </div>

        </div>
    );
};

export default NewReportActions;