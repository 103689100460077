import React, {useRef, useState} from 'react';
import styles from './FootballField.module.css';
import Draggable from "react-draggable";
import PopoverPointer from "../PopoverPointer/PopoverPointer";
import html2canvas from 'html2canvas';

const FootballField = ({screenShot}) => {
    const fieldRef = useRef(null);

    const [playerNumbers, setPlayerNumbers] = useState([]);
    const [saveField, setSaveField] = useState(false);

    const addPlayerNumber = (player) => {
        setPlayerNumbers((prevNumbers) => [...prevNumbers, player]);
    };

    const handleDrag = (e, ui) => {
        const {x, y} = ui;
        const field = fieldRef.current;
        const fieldRect = field.getBoundingClientRect();

        if (
            x < 0 ||
            y < 0 ||
            x + ui.node.clientWidth > fieldRect.width ||
            y + ui.node.clientHeight > fieldRect.height
        ) {
            return false;
        }
    };

    const saveScreenshot = async (e) => {
        e.preventDefault();
        setSaveField(!saveField);

        if (saveField) {
            screenShot(null);
        }

        if (!saveField) {
            const canvas = await html2canvas(fieldRef.current);
            const dataUrl = canvas.toDataURL();

            const response = await fetch(dataUrl);
            const blob = await response.blob();

            const file = new File([blob], 'screenshot.png', { type: 'image/png' });

            screenShot(file);
        }

    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', marginTop: '100px'}}>
            <div style={{display: 'flex', gap: "15px"}}>
                {!saveField &&
                    <button
                        className="btn"
                        style={{
                            fontSize: '10px',
                            width: 'auto',
                            height: '30px',
                            margin: '0 0 15px 0',
                            padding: '0 15px'
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            addPlayerNumber({
                                id: Date.now() + 1,
                                number: 1,
                                backgroundColor: '#fff',
                                textColor: '#000',
                            });
                        }}
                    >
                        Додати гравця
                    </button>
                }

                <button
                    className="btn"
                    style={{fontSize: '10px', width: 'auto', height: '30px', margin: '0 0 15px 0', padding: '0 15px'}}
                    onClick={saveScreenshot}
                >
                    {saveField ? 'Редагувати' : 'Зберегти'}
                </button>
            </div>

            <div
                className={`${styles.footballField} ${saveField ? styles.footballFieldSave : ''}`}
                ref={fieldRef}
            >
                <img className={styles.footballFieldImg} src={require('../../assets/images/football-field.jpg')}
                     alt=""/>
                {playerNumbers.map((playerNumber) => (
                    <Draggable
                        key={playerNumber.id}
                        onDrag={handleDrag}
                        defaultPosition={{x: 0, y: 0}}
                    >
                        <div
                            className={styles.point}
                            style={{
                                background: playerNumber.backgroundColor,
                                color: playerNumber.textColor,
                            }}
                        >
                            <PopoverPointer
                                values={playerNumber}
                                setPlayerNumbers={setPlayerNumbers}
                            >
                                {playerNumber.number}
                            </PopoverPointer>
                        </div>
                    </Draggable>
                ))}
            </div>
        </div>
    );
};

export default FootballField;