import {useEffect, useState} from 'react';
import Header from "../components/Header/Header";
import {Form, message, Select, Input} from "antd";
import axios from "axios";

const CreateTeam = () => {
    const [form] = Form.useForm();
    const [leagues, setLeagues] = useState([]);
    const [leaguesId, setLeagueId] = useState('');
    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
            style: {
                textAlign: 'center',
            },
            type: 'success',
            content: 'Команда була створена',
        });
    };
    useEffect(() => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_URL}api/league/get-all`,
            headers: {"Authorization": document.cookie},
        })
            .then(res => {
                if (res.status === 200) {
                    setLeagues(res.data.data.leagues);
                }
            })
            .catch(error => console.error(error));

    }, []);

    const createTeam = (values) => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_URL}api/team/create`,
            headers: {"Authorization": document.cookie},
            data: values
        })
            .then(res => {
                if (res.status === 200) {
                    success();
                    form.resetFields();
                }
            })
            .catch(error => console.error(error));
    }

    const option = leagues.map(({name, _id}) => {
        return {value: _id, label: name};
    });

    return (
        <>
            <Header/>
            <div className="container pb-container">
                <h2 className="title">Створити команду</h2>
                {contextHolder}
                <Form
                    form={form}
                    name="createTeam"
                    className="form-fpi"
                    onFinish={createTeam}
                >
                    <Form.Item
                        name="leagueId"
                    >
                        <Select
                            className="select-custom"
                            value={leaguesId}
                            showSearch
                            size="large"
                            optionFilterProp="children"
                            placeholder="Виберіть лігу"
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={option}
                            onChange={(value) => setLeagueId(value)}
                        />
                    </Form.Item>
                    {
                        leaguesId && (
                            <>
                                <Form.Item
                                    name="name"
                                    rules={[{required: true, message: 'Введіть назву команди!'}]}>
                                    <Input
                                        placeholder="Назва команди"
                                        size="large"
                                        className="input-custom"
                                    />
                                </Form.Item>

                                <button className="btn" style={{marginTop: '45px'}}>Створити</button>
                            </>

                        )
                    }
                </Form>
            </div>
        </>
    );
};

export default CreateTeam;