import Header from "../components/Header/Header";
import {Form, message} from "antd";
import axios from "axios";
import {Input} from "antd/lib";

const CreateLeague = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();

    const success = () => {
        messageApi.open({
            style: {
                textAlign: 'center',
            },
            type: 'success',
            content: 'Ліга була створена!',
        });
    };

    const createLeague = (values) => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_URL}api/league/create`,
            headers: {"Authorization": document.cookie},
            data: values
        })
            .then(res => {
                if(res.status === 200) {
                    success();
                    form.resetFields();
                }
            })
            .catch(error => console.error(error));
    }

    return (
        <>
            <Header />
            <div className="container pb-container">
                {contextHolder}
                <h2 className="title">Створити лігу</h2>

                <Form
                    form={form}
                    name="createLeague"
                    className="form-fpi"
                    onFinish={createLeague}
                >
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Введіть назву ліги!'
                            }
                        ]}
                    >
                        <Input
                            className="input-custom"
                            placeholder="Ліга"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="country"
                        rules={[
                            {
                                required: true,
                                message: 'Введіть назву країни!'
                            }
                        ]}
                    >
                        <Input
                            className="input-custom"
                            placeholder="Країна"
                            size="large"
                        />
                    </Form.Item>

                    <button
                        style={{marginTop: '45px'}}
                        className="btn"
                    >
                        Створити
                    </button>
                </Form>
            </div>
        </>
    );
};

export default CreateLeague;