import React from 'react';
import {formatName} from "../../helpers/formatName";
import {chartOptions} from "../../configs/charts.config";
import {chartOptions2} from "../../configs/charts2.config";
import ReactECharts from "echarts-for-react";
import {useLocation} from "react-router-dom";

const StatisticLines = ({data, title}) => {
    const location = useLocation();

    return (
        <>
            <h2 className="lines-statistic-title">{title}</h2>

            <ul className="lines-statistic">
                {data.map((player, index) =>
                    <li key={index} className="lines-statistic__item">
                        <img src={`${process.env.REACT_APP_SERVER_URL}${player.img}`} alt={player.name} />
                        <p title={player.name}>{formatName(player.name)}</p>

                        <h2 style={{marginTop: '20px'}}>Загальні дії</h2>
                        {location.pathname === "/new-report-info" ?     <ReactECharts
                            style={{width: '350px', height: '250px', margin: '0 auto'}}
                            option={chartOptions([
                                    { text: `ТТД: ${player.actions.events}`, max: 150 },
                                    { text: `ТТД %: ${player.actions.eventsSuccess}`, max: 100 },
                                    { text: `Інт.: ${player.actions.intensity}`, max: 2 },
                                    { text: `Загальні\n дуелі: ${player.actions.duel}`, max: 25 },
                                    { text: `Дуелі %: ${player.actions['duel %']}`, max: 100 },
                                    { text: `Верхові\n дуелі: ${player.actions.air}`, max: 15 },
                                    { text: `Верхові\n дуелі %: ${player.actions['air %']}`, max: 100 },
                                    { text: `Прискорення: ${player.actions.offTheBall}`, max: 20 },
                                    { text: `Повернення: ${player.actions.defPosition}`, max: 20 },
                                    { text: `Закриття\n зони: ${player.actions.coveringDepth}`, max: 15 },
                                    { text: `Пресинг: ${player.actions.pressingDuel}`, max: 20 },
                                ],
                                [
                                    player.actions.events,
                                    player.actions.eventsSuccess,
                                    player.actions.intensity,
                                    player.actions.duel,
                                    player.actions['duel %'],
                                    player.actions.air,
                                    player.actions['air %'],
                                    player.actions.offTheBall,
                                    player.actions.defPosition,
                                    player.actions.coveringDepth,
                                    player.actions.pressingDuel
                                ], 79)}
                        />:   <ReactECharts
                            style={{width: '350px', height: '250px', margin: '0 auto'}}
                            option={chartOptions2([
                                    { text: `ТТД: ${player.actions.events}`, max: 150 },
                                    { text: `ТТД %: ${player.actions.eventsSuccess}`, max: 100 },
                                    { text: `Інт.: ${player.actions.intensity}`, max: 2 },
                                    { text: `Загальні\n дуелі: ${player.actions.duel}`, max: 25 },
                                    { text: `Дуелі %: ${player.actions['duel %']}`, max: 100 },
                                    { text: `Верхові\n дуелі: ${player.actions.air}`, max: 15 },
                                    { text: `Верхові\n дуелі %: ${player.actions['air %']}`, max: 100 },
                                    { text: `Прискорення: ${player.actions.offTheBall}`, max: 20 },
                                    { text: `Повернення: ${player.actions.defPosition}`, max: 20 },
                                    { text: `Закриття\n зони: ${player.actions.coveringDepth}`, max: 15 },
                                    { text: `Пресинг: ${player.actions.pressingDuel}`, max: 20 },
                                ],
                                [
                                    player.actions.events,
                                    player.actions.eventsSuccess,
                                    player.actions.intensity,
                                    player.actions.duel,
                                    player.actions['duel %'],
                                    player.actions.air,
                                    player.actions['air %'],
                                    player.actions.offTheBall,
                                    player.actions.defPosition,
                                    player.actions.coveringDepth,
                                    player.actions.pressingDuel
                                ], 79)}
                        />}



                        <h2 style={{marginTop: '20px'}}>Рух мʼяча</h2>

                        {location.pathname === "/new-report-info" ?   <ReactECharts
                            style={{width: '350px', height: '250px', margin: '0 auto'}}
                            option={chartOptions([
                                    { text: `Хр: ${player.ballMovement.xp}`, max: 20 },
                                    { text: `Хр +: ${player.ballMovement.xpPlus}`, max: 17 },
                                    { text: `Хр -: ${player.ballMovement.xpMinus}`, max: 10 },
                                    { text: `Загалом\n передач: ${player.ballMovement.pass}`, max: 120 },
                                    { text: `Передачі %: ${player.ballMovement['pass %']}`, max: 100 },
                                    { text: `Паси: ${player.ballMovement.allPass}`, max: 100 },
                                    { text: `Довгі\n передачі: ${player.ballMovement.longRangeKick}`, max: 20 },
                                    { text: `Крос: ${player.ballMovement.cross}`, max: 15 },
                                    { text: `Розрізні\n паси: ${player.ballMovement.smartPass}`, max: 10 },
                                    { text: `Фінальна\n треть: ${player.ballMovement.thirdPart}`, max: 30 },
                                ],
                                [
                                    player.ballMovement.xp,
                                    player.ballMovement.xpPlus,
                                    player.ballMovement.xpMinus,
                                    player.ballMovement.pass,
                                    player.ballMovement['pass %'],
                                    player.ballMovement.allPass,
                                    player.ballMovement.longRangeKick,
                                    player.ballMovement.cross,
                                    player.ballMovement.smartPass,
                                    player.ballMovement.thirdPart
                                ], 79)}
                        />:  <ReactECharts
                            style={{width: '350px', height: '250px', margin: '0 auto'}}
                            option={chartOptions2([
                                    { text: `Хр: ${player.ballMovement.xp}`, max: 20 },
                                    { text: `Хр +: ${player.ballMovement.xpPlus}`, max: 17 },
                                    { text: `Хр -: ${player.ballMovement.xpMinus}`, max: 10 },
                                    { text: `Загалом\n передач: ${player.ballMovement.pass}`, max: 120 },
                                    { text: `Передачі %: ${player.ballMovement['pass %']}`, max: 100 },
                                    { text: `Паси: ${player.ballMovement.allPass}`, max: 100 },
                                    { text: `Довгі\n передачі: ${player.ballMovement.longRangeKick}`, max: 20 },
                                    { text: `Крос: ${player.ballMovement.cross}`, max: 15 },
                                    { text: `Розрізні\n паси: ${player.ballMovement.smartPass}`, max: 10 },
                                    { text: `Фінальна\n треть: ${player.ballMovement.thirdPart}`, max: 30 },
                                ],
                                [
                                    player.ballMovement.xp,
                                    player.ballMovement.xpPlus,
                                    player.ballMovement.xpMinus,
                                    player.ballMovement.pass,
                                    player.ballMovement['pass %'],
                                    player.ballMovement.allPass,
                                    player.ballMovement.longRangeKick,
                                    player.ballMovement.cross,
                                    player.ballMovement.smartPass,
                                    player.ballMovement.thirdPart
                                ], 79)}
                        /> }

                    </li>
                )}
            </ul>
            <div style={{height: "200px"}}></div>
        </>
    );
};

export default StatisticLines;