import {useState} from 'react';
import {Card, Modal} from "antd";
import {DeleteOutlined, EditOutlined, ExclamationCircleFilled} from "@ant-design/icons";

import styles from './Teams.module.css';
import ModalTeam from "../Modal/ModalTeam";
import axios from "axios";
import {renderList} from "../../store/slices/userSlice";
import {useDispatch} from "react-redux";

const logo = require('../../assets/images/player.png');
const { confirm } = Modal;

const TeamCart = ({players, setIsUpdate, playerSearch}) => {
    const dispatch = useDispatch();
    const [playerModal, setPlayerModal] = useState({opened: false, player: {}});
    const [image, setImage] = useState('');

    const editPlayer = (player) => {
        setPlayerModal({opened: true, player});

        if (player.img) {
            setImage(`${process.env.REACT_APP_SERVER_URL}${player.img}`);
        }
    }

    const deletePlayer = (id, name) => {
        confirm({
            title: `Видалити гравця ${name}?`,
            icon: <ExclamationCircleFilled />,
            cancelText: 'Відмінити',
            okText: 'Видалити',
            onOk() {
                axios({
                    method: 'DELETE',
                    url: `${process.env.REACT_APP_SERVER_URL}api/player/delete`,
                    headers: {"Authorization": document.cookie},
                    data: {id: id}
                })
                dispatch(renderList());
            },
        });
    };

    const filteredPlayers = players.filter(({name}) => (name.toLowerCase().includes(playerSearch.toLowerCase())));

    return (
        <>
            <div className={styles.cardWrapper}>
                { filteredPlayers.length ?
                    filteredPlayers.map(({_id, name, nationality, position, img, dateOfBirth, wyscoutId}) => (
                        <div className={styles.card} key={_id}>
                            <Card
                                className={styles.cardContent}
                                style={{width: '100%'}}
                                cover={img
                                    ?
                                    <img
                                        className={styles.playerImg}
                                        src={`${process.env.REACT_APP_SERVER_URL}${img}`}
                                        alt=""
                                    />
                                    : <img className={styles.playerIcon} src={logo} alt=""/>
                                }
                                actions={[
                                    <EditOutlined
                                        key="edit"
                                        onClick={() => editPlayer({_id, name, nationality, position, img, dateOfBirth, wyscoutId})}
                                    />,
                                    <DeleteOutlined
                                        key="remove"
                                        onClick={() => deletePlayer(_id, name)}
                                    />
                                ]}
                            >
                                <h2>{name}</h2>
                                <p>nationality: {nationality}</p>
                                <p>position: {position}</p>
                                <p>date of birth: {dateOfBirth}</p>
                            </Card>
                        </div>
                    )) :
                    <div className="not-found">Гравці відсутні!</div>
                }
            </div>

            <ModalTeam
                setImage={setImage}
                image={image}
                setPlayerModal={setPlayerModal}
                playerModal={playerModal}
                setIsUpdate={setIsUpdate}
            />
        </>

    );
};

export default TeamCart;