import React from 'react';
import {formatName} from "../../helpers/formatName";
import {chartOptions} from "../../configs/charts.config";
import {chartOptions2} from "../../configs/charts2.config"
import ReactECharts from "echarts-for-react";
import { useLocation } from 'react-router-dom';

const StatisticBallMovement = ({data, title, sum}) => {
    const chartValues = [
        sum.xp,
        sum.xpPlus,
        sum.xpMinus,
        sum.pass,
        sum['pass %'],
        sum.allPass,
        sum.longRangeKick,
        sum.cross,
        sum.smartPass,
        sum.thirdPart
    ];

    const filteredData = data.filter((el) => el.allPass > 0 || el.cross > 0 || el.headPass > 0 || el.launch > 0 || el.longRangeKick > 0 || el.pass > 0 || el.smartPass > 0 || el.thirdPart >0 || el.throwInPass >0 || el.xp > 0 || el.xpMinus > 0 || el.xpPlus > 0   );

const location = useLocation();

    return (
        <>
            <h3>{title}</h3>

            <div style={{display: 'flex', position: "relative"}}>
                     <ul className="statistic__list second">
                    <li className="statistic__item second" style={{display: 'flex', alignItems: 'center', gap: '15px'}}>
                        <span style={{width: '139px'}}>Гравець</span>
                        <span style={{width: '42px', textAlign: 'center'}}>Хр</span>
                        <span style={{width: '42px', textAlign: 'center'}}>Хр +</span>
                        <span style={{width: '42px', textAlign: 'center'}}>Хр -</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Загалом передач">Заг. пер.</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Передачі %">Пер. %</span>
                        <span>Паси</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Довгі передачі">Дов. пер.</span>
                        <span style={{width: '35px', textAlign: 'center'}} title="Передачі головою">Пер. г.</span>
                        <span style={{width: '30px', textAlign: 'center'}}>Крос</span>
                        <span style={{width: '30px', textAlign: 'center'}}>Винос</span>
                        <span style={{width: '30px', textAlign: 'center'}}>Аути</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Розрізні паси">Роз. паси</span>
                        <span style={{width: '30px', textAlign: 'center'}} title="Фінальна треть">Фін. треть</span>
                    </li>
                    {filteredData.map((data, index) =>
                        <li key={index} className="statistic__item pdf">
                        <span style={{width: '130px', textAlign: 'left', display: 'flex', gap: '10px'}}>
                            {data.number}
                            <span title={data.name}>{formatName(data.name)}</span>
                        </span>
                            <span style={{width: '40px', textAlign: 'center'}}>{data.xp}</span>
                            <span style={{width: '40px', textAlign: 'center'}}>{data.xpPlus}</span>
                            <span style={{width: '40px', textAlign: 'center'}}>{data.xpMinus}</span>
                            <span style={{width: '20px', textAlign: 'center'}}>{data.pass}</span>
                            <span style={{width: '25px', textAlign: 'center'}}>{data['pass %']}</span>
                            <span style={{width: '20px', textAlign: 'center'}}>{data.allPass}</span>
                            <span style={{width: '25px', textAlign: 'center'}}>{data.longRangeKick}</span>
                            <span style={{width: '30px', textAlign: 'center'}}>{data.headPass}</span>
                            <span style={{width: '30px', textAlign: 'center'}}>{data.cross}</span>
                            <span style={{width: '15px', textAlign: 'center'}}>{data.launch}</span>
                            <span style={{width: '30px', textAlign: 'center'}}>{data.throwInPass}</span>
                            <span style={{width: '30px', textAlign: 'center'}}>{data.smartPass}</span>
                            <span style={{width: '20px', textAlign: 'center'}}>{data.thirdPart}</span>
                        </li>
                    )}
                    <li className="statistic__item">
                        <span style={{width: '130px', textAlign: 'left', display: 'flex', gap: '10px'}}>
                            Сума:
                        </span>
                        <span style={{width: '40px', textAlign: 'center'}}>{sum.xp}</span>
                        <span style={{width: '40px', textAlign: 'center'}}>{sum.xpPlus}</span>
                        <span style={{width: '40px', textAlign: 'center'}}>{sum.xpMinus}</span>
                        <span style={{width: '20px', textAlign: 'center'}}>{sum.pass}</span>
                        <span style={{width: '25px', textAlign: 'center'}}>{sum['pass %']}</span>
                        <span style={{width: '20px', textAlign: 'center'}}>{sum.allPass}</span>
                        <span style={{width: '25px', textAlign: 'center'}}>{sum.longRangeKick}</span>
                        <span style={{width: '30px', textAlign: 'center'}}>{sum.headPass}</span>
                        <span style={{width: '30px', textAlign: 'center'}}>{sum.cross}</span>
                        <span style={{width: '15px', textAlign: 'center'}}>{sum.launch}</span>
                        <span style={{width: '30px', textAlign: 'center'}}>{sum.throwInPass}</span>
                        <span style={{width: '30px', textAlign: 'center'}}>{sum.smartPass}</span>
                        <span style={{width: '20px', textAlign: 'center'}}>{sum.thirdPart}</span>
                    </li>
                </ul>

                {location.pathname === "/new-report-info" ?     <ReactECharts
                    style={{width: '600px', height: '600px', margin: '0 auto'}}
                    option={chartOptions([
                        { text: `Хр: ${sum.xp}`, max: 100 },
                        { text: `Хр плюс: ${sum.xpPlus}`, max: 80 },
                        { text: `Хр мінус: ${sum.xpMinus}`, max: 80 },
                        { text: `Загалом\n передач: ${sum.pass}`, max: 800 },
                        { text: `Передачі %: ${sum['pass %']}`, max: 100 },
                        { text: `Паси: ${sum.allPass}`, max: 700 },
                        { text: `Довгі передачі: ${sum.longRangeKick}`, max: 50 },
                        { text: `Крос: ${sum.cross}`, max: 25 },
                        { text: `Розрізні паси: ${sum.smartPass}`, max: 15 },
                        { text: `Фінальна треть: ${sum.thirdPart}`, max: 200 },
                    ], chartValues, 200)}
                /> :     <ReactECharts
                    style={{width: '600px', height: '600px', margin: '0 auto', position: "absolute", bottom: "-500px", right: "150px"}}
                    option={chartOptions2([
                        { text: `Хр: ${sum.xp}`, max: 100 },
                        { text: `Хр плюс: ${sum.xpPlus}`, max: 80 },
                        { text: `Хр мінус: ${sum.xpMinus}`, max: 80 },
                        { text: `Загалом\n передач: ${sum.pass}`, max: 800 },
                        { text: `Передачі %: ${sum['pass %']}`, max: 100 },
                        { text: `Паси: ${sum.allPass}`, max: 700 },
                        { text: `Довгі передачі: ${sum.longRangeKick}`, max: 50 },
                        { text: `Крос: ${sum.cross}`, max: 25 },
                        { text: `Розрізні паси: ${sum.smartPass}`, max: 15 },
                        { text: `Фінальна треть: ${sum.thirdPart}`, max: 200 },
                    ], chartValues, 200)}
                /> }

            </div>

        </>
    );
};

export default StatisticBallMovement;