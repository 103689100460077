import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {Input} from 'antd';

const {Search} = Input;

const League = () => {
    const [dataAll, setDataAll] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        axios(
            {
                method: 'POST',
                url: `${process.env.REACT_APP_SERVER_URL}api/league/get-all`,
                headers: {"Authorization": document.cookie},
            })
            .then(res => {
                setDataAll(res.data.data.leagues);
                setIsLoading(false);
            })
            .catch(error => console.error(error));

    }, []);


    const allLeagues = dataAll.filter(league => {
        const leagueName = league.name.toLowerCase().includes(searchValue.toLowerCase());
        const leagueCountry = league.country.toLowerCase().includes(searchValue.toLowerCase());

        return leagueName || leagueCountry;

    }).map(league => (
        <li className="league-item" key={league._id}>
            <div
                className="league-cart"
                onClick={() => navigate(`/teams/${league._id}`)}
            >
                <h2>{league.name}</h2>
                <p>{league.country}</p>
            </div>
        </li>
    ))

    return (
        <div className="container pb-container">
            <div className="head-title">
                <div className="title">Всі ліги</div>
                <Search
                    className="search-custom"
                    placeholder="Пошук..."
                    allowClear
                    size="large"
                    style={{width: 350}}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </div>

            <ul className="league-list">
                {isLoading ?
                    <div className="preloader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    : allLeagues}

                {!allLeagues.length && searchValue ?
                    <div className="not-found">За вашим запитом нічого не знайдено</div> : null}
            </ul>

        </div>
    );
};

export default League;