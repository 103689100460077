import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import {Input} from "antd";
import TeamCart from "./TeamCart";
import Header from "../Header/Header";

import styles from './Teams.module.css';
import {useSelector} from "react-redux";

const {Search} = Input;

const Teams = () => {
    const renderPlayer = useSelector(state => state.user.renderList);
    const {id} = useParams();
    const [teams, setTeams] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [playerSearch, setPlayerSearch] = useState('');

    useEffect(() => {
        setIsLoading(true);
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_URL}api/team/get`,
            headers: {"Authorization": document.cookie},
            data: {
                leagueId: id
            }
        })
            .then(res => {
                if (res.data) setTeams(res.data.data.teams);
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, [isUpdate, id, renderPlayer]);

    const allTeams = teams
        .filter(({name}) => (name.toLowerCase().includes(searchValue.toLowerCase())))
        .map(({_id, name, players}) => (
            <div key={_id} className={styles.row}>
                <h2 style={{padding: '15px 15px', textAlign: 'center'}}>{name}</h2>
                <TeamCart
                    players={players}
                    playerSearch={playerSearch}
                    setIsUpdate={() => setIsUpdate(!isUpdate)}
                />
            </div>
        ))

    return (
        <>
            <Header/>
            <div className="container pb-container">
                <div className="head-title">
                    <div className="title">Команди</div>
                    <Search
                        className="search-custom"
                        placeholder="Пошук команди"
                        allowClear
                        size="large"
                        style={{width: 350, margin: '0 25px 0 auto'}}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />

                    <Search
                        className="search-custom"
                        placeholder="Пошук гравця"
                        allowClear
                        size="large"
                        style={{width: 350}}
                        value={playerSearch}
                        onChange={(e) => setPlayerSearch(e.target.value)}
                    />
                </div>

                <div className={styles.teamsWrapper}>
                    {isLoading ?
                        <div className="preloader">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        : allTeams}

                    {!allTeams.length && searchValue ? <div className="not-found">Nothing was found for your search</div> : null}
                </div>
            </div>
        </>
    );
};

export default Teams;