import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Form, Input, Modal} from "antd";
import {CloseCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

import styles from "../Teams/Teams.module.css";
import axios from "axios";

const dateFormat = 'YYYY-MM-DD';

const ModalTeam = ({setImage, image, playerModal, setPlayerModal, setIsUpdate}) => {

    const [playerInfo, setPlayerInfo] = useState(
        {
            opened: false
        });

    const clearImg = () => {
        setPlayerInfo({...playerInfo, editImage: true});
        setImage('');
    }

    useEffect(() => {
        setPlayerInfo(
            {
                img: playerModal.player.img,
                name: playerModal.player.name,
                nationality: playerModal.player.nationality,
                position: playerModal.player.position,
                dateOfBirth: playerModal.player.dateOfBirth,
                wyscoutId: playerModal.player.wyscoutId,
                editImage: false
            }
        )
    }, [playerModal]);

    const sendHandler = () => {
        const formData = new FormData();

        formData.append('id', playerModal.player._id);
        formData.append('name', playerInfo.name);
        formData.append('editImage', playerInfo.editImage);
        formData.append('nationality', playerInfo.nationality);
        formData.append('position', playerInfo.position);
        formData.append('wyscoutId', playerInfo.wyscoutId);
        formData.append('dateOfBirth', playerInfo.dateOfBirth);
        if (playerInfo.imgFile) formData.append('playerImage', playerInfo.imgFile);


        axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_SERVER_URL}api/player/edit`,
            data: formData,
            headers: {"Authorization": document.cookie, "Content-Type": "multipart/form-data"}
        })
            .then(res => {
                if (res.status === 200) {
                    setIsUpdate(true);
                }
            })
            .catch(error => console.error(error));

        setPlayerModal({opened: false, player: {}})
    }

    const imgUploadHandler = (e) => {
        const reader = new FileReader();
        const file = e.target.files[0];
        setPlayerInfo({
            ...playerInfo,
            imgFile: e.target.files[0],
            editImage: true
        });
        reader.onload = (upload) => {
            setImage(upload.target.result)
        };
        reader.readAsDataURL(file);
    }

    return (
        <Modal
            title="Edit Player"
            open={playerModal.opened}
            onCancel={() => {
                setPlayerModal({opened: false, player: {}});
                setImage('');
            }}
            footer={null}
            destroyOnClose
        >
            <Form>
                <div
                    className={image ? styles.uploaded : ''}
                    style={{marginBottom: '15px'}}
                >
                    <input type="file"
                           name="image"
                           accept=".png,.jpg,.jpeg"
                           className={styles.inputFile}
                           id="playerImage"
                           onChange={imgUploadHandler}
                    />
                    <label htmlFor="playerImage" className={styles.labelFile}><PlusCircleOutlined
                        className={styles.labelFileIcon}/></label>
                    <span className={styles.uploadedImg}>
                            {
                                image ? (
                                    <img src={image} alt="user_img"/>
                                ) : null
                            }

                        <button
                            className={styles.imgRemove}
                            onClick={clearImg}
                        >
                            <CloseCircleOutlined/>
                        </button>
                    </span>
                </div>
                <Input
                    defaultValue={playerInfo.wyscoutId}
                    placeholder="Wyscout id"
                    size="large"
                    style={{marginBottom: '15px'}}
                    onInput={(e) => setPlayerInfo({...playerInfo, wyscoutId: e.target.value})}
                />
                <Input
                    defaultValue={playerInfo.name}
                    placeholder="Name"
                    size="large"
                    style={{marginBottom: '15px'}}
                    onInput={(e) => setPlayerInfo({...playerInfo, name: e.target.value})}
                />
                <Input
                    defaultValue={playerInfo.nationality}
                    placeholder="nationality"
                    size="large"
                    style={{marginBottom: '15px'}}
                    onInput={(e) => setPlayerInfo({...playerInfo, nationality: e.target.value})}
                />
                <Input
                    defaultValue={playerInfo.position}
                    placeholder="position"
                    size="large"
                    style={{marginBottom: '15px'}}
                    onInput={(e) => setPlayerInfo({...playerInfo, position: e.target.value})}
                />
                <DatePicker
                    className="color-black"
                    style={{width: '100%', marginBottom: '15px'}}
                    size="large"
                    placeholder={playerInfo.dateOfBirth}
                    format={dateFormat}
                    onChange={(e, dataString) => setPlayerInfo({...playerInfo, dateOfBirth: dataString})}
                />
                <Button
                    type="primary"
                    size="large"
                    style={{width: '100%'}}
                    onClick={sendHandler}
                >
                    Оновити
                </Button>
            </Form>
        </Modal>
    );
};

export default ModalTeam;