import React from 'react';
import LoginForm from "../components/Login/LoginForm";
import {Navigate} from "react-router-dom";

const Login = () => {

    if (document.cookie.length > 0) {
        return <Navigate to="/" />
    }

    return (
        <LoginForm />
    );
};

export default Login;