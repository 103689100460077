import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    userInfo: {},
    userValue: {
        name: '',
        email: '',
        password: '',
        role: ''
    },
    allUsers: [],
    renderList: false,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        currentUser: (state, action) => {
            state.userInfo = { ...state.userInfo, ...action.payload };
        },
        clearCurrentUser: () => initialState,
        changeUserValue: (state, action) => {
            state.userValue = { ...state.userValue, ...action.payload };
        },
        getAllUsers: (state, action) => {
            state.allUsers = action.payload;
        },
        renderList: (state) => {
            state.renderList = !state.renderList;
        }
    },
});

export const { currentUser, changeUserValue, getAllUsers, renderList, clearCurrentUser } = userSlice.actions;

export default userSlice.reducer;