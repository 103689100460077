import {useNavigate} from "react-router-dom";
import axios from "axios"

import {ReactComponent as Logo} from "../../assets/images/logo.svg";
import {LockOutlined, EyeInvisibleOutlined, EyeTwoTone, MailOutlined} from '@ant-design/icons';
import {Form, Input, message} from 'antd';

import styles from './LoginForm.module.css';

const LoginForm = () => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const errorMsg = () => {
        messageApi.open({
            style: {
                textAlign: 'center',
            },
            type: 'error',
            content: 'Ви ввели неправильну адресу електронної пошти або пароль!',
        });
    };

    const sendFormHandler = (values) => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_URL}api/user/login`,
            data: values,
            credentials: 'include',
        }).then(res => {
            if (res.status === 200) {
                document.cookie = `Authorization=Bearer ${res.data.data.token}; path=/; Secure;`;
                if (res.data.data.token) return navigate('/');
            }
        }).catch(errorMsg)
    }

    return (
        <div className={styles.root}>
            {contextHolder}
            <Logo />

            <Form
                name="normal_login"
                className={styles.form}
                initialValues={{ remember: true }}
                onFinish={sendFormHandler}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Будь ласка, введіть свою електронну адресу!'
                        },
                        {
                            type: 'email',
                            message: 'Введений недійсний E-mail!',
                        },
                    ]}
                >
                    <Input
                        className="input-custom"
                        prefix={<MailOutlined className="site-form-item-icon" />}
                        placeholder="Email"
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Будь ласка, введіть свій пароль!' }]}
                >
                    <Input.Password
                        className="input-custom"
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        size="large"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>

                <button
                    className="btn"
                >
                    Авторизуватися
                </button>
            </Form>
        </div>
    );
};

export default LoginForm;