export const objectToValuesArray = (obj) => {
    const result = [];

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            result.push(obj[key]);
        }
    }

    return result;
}