import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    newReport: [],
    currentNewReport: {}
};

const newReportSlice = createSlice({
    name: "newReport",
    initialState,
    reducers: {
        getNewReports: (state, action) => {
            state.newReport = action.payload;
        },
        getCurrentNewReport: (state, action) => {
            state.currentNewReport = action.payload;
        }
    }
});

export const { getNewReports, getCurrentNewReport } = newReportSlice.actions;

export default newReportSlice.reducer;