import React, {useEffect, useRef, useState} from 'react';
import {InputNumber, Popover} from "antd";

const PopoverPointer = ({children, values, setPlayerNumbers}) => {
    const popoverRef = useRef(null);

    const [visible, setVisible] = useState(false);
    const [number, setNumber] = useState(values.number);
    const [backgroundColor, setBackgroundColor] = useState(`${values.backgroundColor}`);
    const [textColor, setTextColor] = useState(`${values.textColor}`);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                setVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setPlayerNumbers((prevNumbers) =>
            prevNumbers.map((playerNumber) =>
                playerNumber.id === values.id
                    ? { ...playerNumber, number, backgroundColor, textColor }
                    : playerNumber
            )
        );
    }, [number, backgroundColor, textColor, setPlayerNumbers, values.id]);

    const handleDoubleClick = () => {
        setVisible(!visible);
    };

    const deletePoint = () => {
        setPlayerNumbers((prevNumbers) =>
            prevNumbers.filter((playerNumber) => playerNumber.id !== values.id)
        );
    };

    const content = (
        <div ref={popoverRef}>
            <label style={{display: 'flex', alignItems: 'center'}}>
                Номер гравця:
                <InputNumber
                    style={{width: '60px', marginLeft: '10px'}}
                    min={1}
                    max={99}
                    value={number}
                    onChange={(value) => setNumber(value)}
                />
            </label>
            <label style={{display: 'flex', alignItems: 'center', margin: '10px 0'}}>
                Колір форми:
                <input
                    style={{marginLeft: '10px'}}
                    type="color"
                    value={backgroundColor}
                    onChange={(e) => setBackgroundColor(e.target.value)}
                />
            </label>
            <label style={{display: 'flex', alignItems: 'center'}}>
                Колір цифри:
                <input
                    style={{marginLeft: '10px'}}
                    type="color"
                    value={textColor}
                    onChange={(e) => setTextColor(e.target.value)}
                />
            </label>
            <span onClick={deletePoint} style={{ display: 'block', marginTop: "10px", color: 'red', cursor: 'pointer' }}>
                Видалити
            </span>
        </div>

    )

    return (
        <Popover
            open={visible}
            content={content}
            trigger="none"
        >
            <div onDoubleClick={handleDoubleClick} style={{width: '100%', height: '100%', textAlign: 'center', lineHeight: '25px'}}>{children}</div>
        </Popover>
    );
};

export default PopoverPointer;