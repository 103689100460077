import React from 'react';
import {chartOptions} from "../../configs/charts.config";
import {chartOptions2} from "../../configs/charts2.config";
import ReactECharts from "echarts-for-react";
import { useLocation } from 'react-router-dom';

const StatisticGoalkeeper = ({data}) => {
    console.log(data);
    const location = useLocation();
    return (
        <ul className="goalkeepers">
            {data.map((data, index) =>

                <li key={index} style={{display: 'flex', gap: '25px', position: "relative"}}>
                    <div className="goalkeepers__head">
                        <img src={`${process.env.REACT_APP_SERVER_URL}${data.img}`} alt={data.name} />
                        <div>
                            <div><span>Воротар</span>{data.name}</div>
                        </div>
                    </div>
                    <div  className="goalkeepers__statistic">
                        <h2 style={{textAlign: 'left'}}>Статистика</h2>
                        <ul className="goalkeepers__list">
                            <li>
                                <b></b>
                                <span>Матч</span>
                                <span>1 тайм</span>
                                <span>2 тайм</span>
                            </li>
                            <li>
                                <b>Зіграні хв. </b>
                                <span>{data.allHalf.minutes}</span>
                                <span>{data.halfOne.minutes}</span>
                                <span>{data.halfTwo.minutes}</span>
                            </li>
                            <li>
                                <b>Хр</b>
                                <span>{data.allHalf.xp}</span>
                                <span>{data.halfOne.xp}</span>
                                <span>{data.halfTwo.xp}</span>
                            </li>
                            <li>
                                <b>Хр плюс </b>
                                <span>{data.allHalf.xpPlus}</span>
                                <span>{data.halfOne.xpPlus}</span>
                                <span>{data.halfTwo.xpPlus}</span>
                            </li>
                            <li>
                                <b>Хр мінус</b>
                                <span>0</span>
                                <span>0</span>
                                <span>0</span>
                            </li>
                            <li>
                                <b>Паси</b>
                                <span>{data.allHalf.pass}</span>
                                <span>{data.halfOne.pass}</span>
                                <span>{data.halfTwo.pass}</span>
                            </li>
                            <li>
                                <b>Передачі %</b>
                                <span>{data.allHalf['pass %']}</span>
                                <span>{data.halfOne['pass %']}</span>
                                <span>{data.halfTwo['pass %']}</span>
                            </li>
                            <li>
                                <b>Загалом передач</b>
                                <span>{data.allHalf.allPass}</span>
                                <span>{data.halfOne.allPass}</span>
                                <span>{data.halfTwo.allPass}</span>
                            </li>
                            <li>
                                <b>Довгі передачі</b>
                                <span>{data.allHalf.longRangeKick}</span>
                                <span>{data.halfOne.longRangeKick}</span>
                                <span>{data.halfTwo.longRangeKick}</span>
                            </li>
                            <li>
                                <b>Винос</b>
                                <span>{data.allHalf.launch}</span>
                                <span>{data.halfOne.launch}</span>
                                <span>{data.halfTwo.launch}</span>
                            </li>
                            <li>
                                <b>Передачі рукою</b>
                                <span>{data.allHalf.handPass}</span>
                                <span>{data.halfOne.handPass}</span>
                                <span>{data.halfTwo.handPass}</span>
                            </li>
                            <li>
                                <b>Фінальна треть</b>
                                <span>{data.allHalf.thirdPart}</span>
                                <span>{data.halfOne.thirdPart}</span>
                                <span>{data.halfTwo.thirdPart}</span>
                            </li>
                            <li>
                                <b>Сейви</b>
                                <span>{data.allHalf.save}</span>
                                <span>{data.halfOne.save}</span>
                                <span>{data.halfTwo.save}</span>
                            </li>
                            <li>
                                <b>Виходи</b>
                                <span>{data.allHalf.exit}</span>
                                <span>{data.halfOne.exit}</span>
                                <span>{data.halfTwo.exit}</span>
                            </li>
                            <li>
                                <b>ТТД</b>
                                <span>{data.allHalf.events}</span>
                                <span>{data.halfOne.events}</span>
                                <span>{data.halfTwo.events}</span>
                            </li>
                            <li>
                                <b>ТТД %</b>
                                <span>{data.allHalf.eventsSuccess}</span>
                                <span>{data.halfOne.eventsSuccess}</span>
                                <span>{data.halfTwo.eventsSuccess}</span>
                            </li>
                            <li>
                                <b>Інтенсивність</b>
                                <span>{data.allHalf.intensity}</span>
                                <span>{data?.halfOne?.intensity}</span>

                                <span>{data?.halfTwo?.intensity}</span>
                            </li>
                        </ul>
                    </div>
                    {location.pathname === "/new-report-info" ?     <ReactECharts
                        style={{width: '490px', height: '490px', margin: '0 auto'}}
                        option={chartOptions([
                                { text: `Хр: ${data.allHalf.xp}`, max: 5 },
                                { text: `Хр плюс: ${data.allHalf.xpPlus}`, max: 5 },
                                { text: 'Хр мінус: 0', max: 0 },
                                { text: `Загалом\n передач: ${data.allHalf.allPass}`, max: 50 },
                                { text: `Передачі %: ${data.allHalf['pass %']}`, max: 40 },
                                { text: `Фінальна треть: ${data.allHalf.thirdPart}`, max: 20 },
                                { text: `Сейви: ${data.allHalf.save}`, max: 10 },
                                { text: `Виходи: ${data.allHalf.exit}`, max: 10 },
                                { text: `ТТД: ${data.allHalf.events}`, max: 80 },
                                { text: `ТТД %: ${data.allHalf.eventsSuccess}`, max: 100 },
                                { text: `Інтенсивність: ${data.intensity}`, max: 2 },
                            ],
                            [
                                data.allHalf.xp,
                                data.allHalf.xpPlus,
                                0,
                                data.allHalf.allPass,
                                data.allHalf['pass %'],
                                data.allHalf.thirdPart,
                                data.allHalf.save,
                                data.allHalf.exit,
                                data.allHalf.events,
                                data.allHalf.eventsSuccess,
                                data.intensity
                            ], 130)}
                    />:     <ReactECharts
                        style={{width: '490px', height: '490px', margin: '0 auto', position: "absolute", right: "150px", bottom: "-600px" }}
                        option={chartOptions2([
                                { text: `Хр: ${data.allHalf.xp}`, max: 5 },
                                { text: `Хр плюс: ${data.allHalf.xpPlus}`, max: 5 },
                                { text: 'Хр мінус: 0', max: 0 },
                                { text: `Загалом\n передач: ${data.allHalf.allPass}`, max: 50 },
                                { text: `Передачі %: ${data.allHalf['pass %']}`, max: 40 },
                                { text: `Фінальна треть: ${data.allHalf.thirdPart}`, max: 20 },
                                { text: `Сейви: ${data.allHalf.save}`, max: 10 },
                                { text: `Виходи: ${data.allHalf.exit}`, max: 10 },
                                { text: `ТТД: ${data.allHalf.events}`, max: 80 },
                                { text: `ТТД %: ${data.allHalf.eventsSuccess}`, max: 100 },
                                { text: `Інтенсивність: ${data.intensity}`, max: 2 },
                            ],
                            [
                                data.allHalf.xp,
                                data.allHalf.xpPlus,
                                0,
                                data.allHalf.allPass,
                                data.allHalf['pass %'],
                                data.allHalf.thirdPart,
                                data.allHalf.save,
                                data.allHalf.exit,
                                data.allHalf.events,
                                data.allHalf.eventsSuccess,
                                data.intensity
                            ], 130)}
                    />}

                </li>
            )}
        </ul>
    );
};

export default StatisticGoalkeeper;