import {Button, Form, Input, Modal, DatePicker, Upload, message, Select} from "antd";
import 'dayjs/locale/uk';
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/uk_UA';
import {UploadOutlined} from "@ant-design/icons";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {currentReport, toggleLoading} from "../../store/slices/analyticsSlice";
import {dateFormat} from "../../helpers/dateFormat";
import {useEffect, useState} from "react";

const ModalUpdateOldReport = ({modalUpdateOldReport, setModalUpdateOldReport}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const report = useSelector(state => state.analytics.report);
    const [modalReportValue, setModalReportValue] = useState(report);

    useEffect(() => {
        form.resetFields();
        setModalReportValue(report);
    }, [report, form]);

    const displaySuccessMessage = () => {
        messageApi.open({
            style: {textAlign: 'center'},
            type: 'success',
            content: 'Доклад оновлено!',
        });
    };

    const updateOldReport = async (values) => {

        const formData = new FormData();
        formData.append('id', report._id);
        formData.append('name', values.name);
        formData.append('type', values.type);
        formData.append('date', dateFormat(values.date));
        formData.append('report', values.report);

        try {
            const {status} = await axios.put(`${process.env.REACT_APP_SERVER_URL}api/reports/old/edit`, formData, {
                headers: {Authorization: document.cookie},
            });

            if (status === 200) {
                form.resetFields();
                displaySuccessMessage();
                dispatch(toggleLoading());
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onClose = () => {
        dispatch(currentReport({}))
        setModalUpdateOldReport(false);
    };

    return (
        <>
            {contextHolder}
            <Modal
                title="Редагувати доклад"
                width="350px"
                open={modalUpdateOldReport === true}
                onCancel={onClose}
                footer={null}
                destroyOnClose
            >
                <Form
                    form={form}
                    name="updateReport"
                    autoComplete="off"
                    onFinish={updateOldReport}
                >
                    <Form.Item
                        name="name"
                        style={{margin: '20px 0 0'}}
                        initialValue={modalReportValue.name}
                        rules={[
                            {
                                required: true,
                                message: 'Введіть назву!'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Назва"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="date"
                        initialValue={dayjs(modalReportValue.date)}
                        rules={[
                            {
                                required: true,
                                message: 'Виберіть дату!'
                            }
                        ]}
                    >
                        <DatePicker
                            placeholder="Виберіть дату"
                            locale={locale}
                            className="color-black"
                            style={{width: '100%', marginTop: '15px'}}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="type"
                        initialValue={modalReportValue.type}
                    >
                        <Select
                            style={{width: '100%'}}
                            size="large"
                            options={[
                                {value: 'kolos', label: 'kolos'},
                                {value: 'scouting', label: 'scouting'},
                                {value: 'kolos_u19', label: 'kolos_u19'},
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        name="report"
                        getValueFromEvent={(e) => e.file}
                        valuePropName="file"
                        initialValue={`${process.env.REACT_APP_SERVER_URL}${modalReportValue.url}`}
                        rules={[
                            {
                                required: true,
                                message: 'Загрузіть pdf файл!'
                            }
                        ]}
                    >
                        <Upload
                            maxCount={1}
                            accept=".pdf"
                            className="file-label"
                            fileList={
                            report.url
                                ? [{
                                        uid: '-1',
                                        name: `${report.url.replace('reports/', '')}`,
                                        url: `${process.env.REACT_APP_SERVER_URL}${report.url}`,
                                        status: 'done'
                                    }]
                                : []
                        }
                            beforeUpload={file => {
                                const reader = new FileReader();
                                reader.readAsText(file);
                                return false;
                            }}
                        >
                            <Button style={{width: '100%'}} size="large">
                                <UploadOutlined/> Загрузити pdf
                            </Button>
                        </Upload>
                    </Form.Item>

                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        style={{width: '100%'}}
                    >
                        Редагувати
                    </Button>
                </Form>
            </Modal>
        </>
    );
};

export default ModalUpdateOldReport;