import React from 'react';
import {ReactComponent as Ball} from "../../assets/images/ball.svg";
import {ReactComponent as RedCard} from "../../assets/images/red-card.svg";
import {ReactComponent as YellowCard} from "../../assets/images/yellow-card.svg";
import {UserDeleteOutlined, VerticalAlignMiddleOutlined} from "@ant-design/icons";
import NewReportPlayer from "./NewReportPlayer";

const NewReportTeam = (
    {
        team,
        teamPlayers,
        category = "",
        goalsPlayer = 0,
        positionPlayer = true,
        numberPlayer = true,
        namePlayer = true,
        statusPlayer = true,
        replacementTime = true,
        setTeamPlayers,
        playersFirstTeam,
        playersSecondTeam,
        selectedPlayers,
        setSelectedPlayers
    }
) => {

    const createPlayerOptions = (players) => {
        if (players) {
            return players.map((player) => ({
                value: player._id,
                label: `${player.name}`,
            }));
        }
    };

    const addPlayer = (team, category) => {
        setTeamPlayers((prevState) => {
            const currentTeam = prevState[team];
            return {
                ...prevState,
                [team]: {
                    ...currentTeam,
                    [category]: [...currentTeam[category], currentTeam[category].length + 1],
                },
            };
        });
    };

    const removePlayer = (team, category, playerIndex) => {
        setTeamPlayers((prevState) => {
            const currentTeam = prevState[team];
            return {
                ...prevState,
                [team]: {
                    ...currentTeam,
                    [category]: currentTeam[category].filter((_, index) => index !== playerIndex),
                },
            };
        });
    };

    return (
        <div className="nr-body__team">
            <div className="nr-body__titles">
                {positionPlayer && <div style={{width: '95px'}}>Позиція</div>}
                {numberPlayer && <div>Номер</div>}
                {namePlayer && <div style={{width: '195px'}}>Імʼя гравця</div>}
                {replacementTime &&
                    <VerticalAlignMiddleOutlined style={{fontSize: '20px', color: '#fff', width: '25px',  textAlign: 'center'}} />
                }
                {statusPlayer ?
                    <div style={{maxWidth:"75px"}}>
                        <Ball/>
                        <RedCard/>
                        <YellowCard/>
                    </div>
                    :
                    <div style={{display: "flex", gap: "5px", maxWidth: "45px"}}>

                        <RedCard/>
                        <YellowCard/>
                    </div>

                }
            </div>
            {teamPlayers.map((playerIndex, index) => (
                <div className="nr-body__player" key={playerIndex}>
                    <NewReportPlayer
                        team={team}
                        goalsPlayer={goalsPlayer}
                        category={category}
                        player={`${playerIndex - 1}`}
                        positionPlayer={positionPlayer}
                        replacementTime={replacementTime}
                        numberPlayer={numberPlayer}
                        namePlayer={namePlayer}
                        statusPlayer={statusPlayer}
                        selectedPlayers={selectedPlayers}
                        setSelectedPlayers={setSelectedPlayers}
                        playerOptions={team === "teamFirst" ? createPlayerOptions(playersFirstTeam, selectedPlayers) : createPlayerOptions(playersSecondTeam, selectedPlayers)}
                    />

                    <button className="nr-btn nr-btn--remove" onClick={() => removePlayer(team, category, index)}>
                        <UserDeleteOutlined/>
                    </button>
                </div>
            ))}

            <button className="btn nr-btn--add" onClick={(e) => {
                e.preventDefault()
                addPlayer(team, category)
            }}>
                додати гравця
            </button>
        </div>
    );
};

export default NewReportTeam;