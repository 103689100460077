import {useEffect, useState} from 'react';
import Header from "../components/Header/Header";
import {DatePicker, Form, message, Select} from "antd";
import axios from "axios";
import {Input} from "antd/lib";
import {CloseCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import styles from "../components/Teams/Teams.module.css";

const CreatePlayer = () => {
    const [form] = Form.useForm();

    const [teamLeagues, setTeamLeagues] = useState([]);
    const [teams, setTeams] = useState([]);
    const [takeLeagues, setTakeLeagues] = useState({id: null});
    const [takeTeam, setTakeTeam] = useState({id: null});
    const [image, setImage] = useState('');
    const [messageApi, contextHolder] = message.useMessage();
    const [playerFile, setPlayerFile] = useState();

    const success = () => {
        messageApi.open({
            style: {
                textAlign: 'center',
            },
            type: 'success',
            content: 'Гравця створено!',
        });
    };

    useEffect(() => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_URL}api/league/get-all`,
            headers: {"Authorization": document.cookie},
        })
            .then(res => {
                if(res.data) {
                    setTeamLeagues(res.data.data.leagues);
                }
            })
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        if (takeLeagues.id) {
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_SERVER_URL}api/team/get`,
                headers: {"Authorization": document.cookie},
                data: {
                    leagueId: takeLeagues.id
                }
            })
                .then(res => {
                    if(res.data) {
                        setTeams(res.data.data.teams);
                    }
                })
                .catch(error => console.error(error));
        }

    }, [takeLeagues]);

    const optionLeagues = teamLeagues.map(({name, _id}) => {
        return {value: _id, label: name};
    });
    const optionTeams = teams.map(({name, _id}) => {
        return {value: _id, label: name};
    });

    const imgUploadHandler = (e) => {
        const reader = new FileReader();
        const file = e.target.files[0];
        setPlayerFile(e.target.files[0]);
        reader.onload = (upload) => {
            setImage(upload.target.result)
        };
        reader.readAsDataURL(file);
    }

    const createPlayer = (values) => {
        const formData = new FormData();

        formData.append('teamId',  takeTeam.id);
        formData.append('name', values.name);
        formData.append('nationality', values.nationality);
        formData.append('position', values.position);
        formData.append('wyscoutId', values.wyscoutId);
        formData.append('dateOfBirth', new Date(values.dateOfBirth).toLocaleDateString());
        if (playerFile) formData.append('playerImage', playerFile);

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_URL}api/player/create`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": document.cookie
            },
        })
            .then(res => {
                if (res.data) {
                    setImage('');
                    setPlayerFile(null);
                }
                form.resetFields();
                success();
            })
            .catch(error => console.error(error));
    }

    return (
        <>
            {contextHolder}
            <Header />
            <div className="container pb-container">
                <h2 className="title">Створити гравця</h2>

                <Form
                    form={form}
                    name="createPlayer"
                    className="form-fpi"
                    onFinish={createPlayer}
                >
                    <Form.Item>
                        <Select
                            className="select-custom"
                            value={takeLeagues.id || null}
                            showSearch
                            size="large"
                            optionFilterProp="children"
                            placeholder="Виберіть лігу"
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={optionLeagues}
                            onChange={(value) => {
                                setTakeTeam({...takeTeam, id: null})
                                setTakeLeagues({...takeLeagues, id: value})
                            }}
                        />
                    </Form.Item>
                    {
                        takeLeagues.id && (
                            <Form.Item>
                                <Select
                                    className="select-custom"
                                    value={takeTeam.id || null}
                                    showSearch
                                    size="large"
                                    optionFilterProp="children"
                                    placeholder="Виберіть команду"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={optionTeams}
                                    onChange={(value) => setTakeTeam({...takeTeam, id: value})}
                                />
                            </Form.Item>
                        )
                    }

                    {
                        takeTeam.id && (
                            <>
                                <div
                                    className={image ? styles.uploaded : ''}
                                    style={{marginBottom: '15px'}}
                                >
                                    <input type="file"
                                           name="image"
                                           accept=".png,.jpg,.jpeg"
                                           className={styles.inputFile}
                                           id="playerImage"
                                           onChange={imgUploadHandler}
                                    />
                                    <label htmlFor="playerImage" className={styles.labelFile}><PlusCircleOutlined
                                        className={styles.labelFileIcon}/></label>
                                    <span className={styles.uploadedImg}>
                                        {
                                            image ? (
                                                <img src={image} alt="user_img"/>
                                            ) : null
                                        }

                                        <button
                                            className={styles.imgRemove}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setImage('');
                                            }}>
                                            <CloseCircleOutlined/>
                                        </button>
                                    </span>
                                </div>
                                <Form.Item
                                    name="wyscoutId"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введіть Wyscout id'
                                        }
                                    ]}
                                >
                                    <Input
                                        className="input-custom"
                                        placeholder="Wyscout id"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введіть ім\'я гравця!'
                                        }
                                    ]}
                                >
                                    <Input
                                        className="input-custom"
                                        placeholder="Ім'я гравця"
                                        size="large"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="position"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введіть позицію гравця!'
                                        }
                                    ]}
                                >
                                    <Input
                                        className="input-custom"
                                        placeholder="Позиція гравця"
                                        size="large"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="nationality"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введіть національність гравця!'
                                        }
                                    ]}
                                >
                                    <Input
                                        className="input-custom"
                                        placeholder="Національність гравця"
                                        size="large"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="dateOfBirth"
                                    rules={[{required: true, message: 'Введіть дату народженн!'}]}
                                >
                                    <DatePicker
                                        className="input-custom"
                                        style={{width: '100%'}}
                                        size="large"
                                        allowClear={true}
                                        placeholder="Дата народження"
                                        format='YYYY-MM-DD'
                                    />
                                </Form.Item>

                                <button className="btn" style={{marginTop: '45px'}}>Створити</button>
                            </>
                        )
                    }
                </Form>
            </div>
        </>
    );
};

export default CreatePlayer;